import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { DateTimePicker } from '@material-ui/pickers';
import { timezone } from '../../../../utils/adapter';

const NoOffersDialog = ({ onClose }) => {
  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
      <DialogTitle id="form-dialog-title">Manual discount offer activation</DialogTitle>
      <DialogContent>
        <DialogContentText>Unfortunately there are no campaigns available for this client</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NoOffersDialog.propTypes = {
  onClose: PropTypes.func,
};

const SelectCampaignContent = ({
  selectedCampaign,
  onClose,
  onSelectCampaign,
  onShowCampaign,
  suitableDiscountOffers,
}) => {
  return (
    <>
      <DialogContent>
        <DialogContentText>Select discount campaign to create an offer:</DialogContentText>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Campaign ID</TableCell>
              <TableCell>Campaign name</TableCell>
              <TableCell>Category of discount</TableCell>
              <TableCell>Discount amount</TableCell>
              <TableCell>Payment amount</TableCell>
              <TableCell>Number of payments </TableCell>
              <TableCell>Offer valid days</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suitableDiscountOffers.map(row => (
              <TableRow key={row.campaign_id}>
                <TableCell>{row.campaign_id}</TableCell>
                <TableCell>{row.campaign_name}</TableCell>
                <TableCell>{row.campaign_category}</TableCell>
                <TableCell>{row.discount_amount.toFixed(2)}</TableCell>
                <TableCell>{row.amount_to_pay.toFixed(2)}</TableCell>
                <TableCell>{row.max_payments === 0 ? 'No limits' : row.max_payments}</TableCell>
                <TableCell>{row.campaign_valid_offer_days}</TableCell>
                <TableCell align="right">
                  <RadioGroup row>
                    <FormControlLabel
                      control={<Radio />}
                      checked={selectedCampaign === row.campaign_id}
                      onChange={() => onSelectCampaign(row.campaign_id)}
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={onShowCampaign} color="primary" variant="contained" disabled={selectedCampaign === 0}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

SelectCampaignContent.propTypes = {
  suitableDiscountOffers: PropTypes.array,
  selectedCampaign: PropTypes.number,
  onClose: PropTypes.func,
  onShowCampaign: PropTypes.func,
  onSelectCampaign: PropTypes.func,
};

const AddManualDiscountDialog = ({ onClose, onSubmit, suitableDiscountOffers, loanId, userId }) => {
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const [showCampaign, setShowCampaign] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [precalculation, setPrecalculation] = useState(null);
  const [endAtDate, setEndAtDate] = useState(null);
  const [startAtDate, setStartAtDate] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [saving, setSaving] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      await dataProvider
        .query(`discount_campaigns/precalculation?campaign_id=${selectedCampaign}&loan_id=${loanId}`, { method: 'GET' })
        .then(({ data }) => {
          setPrecalculation(data.data);
          setEndAtDate(data.data.valid_to ? new Date(data.data.valid_to) : new Date());
        })
        .catch(error => notify(`Error: ${error.message}`, 'error'));
      await dataProvider
        .query(`discount_campaigns/${selectedCampaign}`, { method: 'GET' })
        .then(({ data }) => setCampaignData(data))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
      setLoadingData(false);
    };
    if (showCampaign) {
      fetchData();
    }
  }, [dataProvider, showCampaign, loanId, notify, selectedCampaign]);

  useEffect(() => {
    setStartAtDate(new Date());
  }, []);

  const handleSubmit = () => {
    setLoadingCheck(true);
    dataProvider
      .getList('discount_offers', {
        filter: { 'loan.id': loanId },
        pagination: { page: 1, perPage: 30 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data }) => {
        const hasActiveOffers = data.some(offer => offer.state === 'active');
        setLoadingCheck(false);
        hasActiveOffers ? setShowConfirm(true) : createOffer();
      })
      .catch(error => {
        setLoadingCheck(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const createOffer = () => {
    setSaving(true);
    const payload = {
      discount_campaign_id: campaignData.id,
      category: campaignData.discount_category,
      loan_id: loanId,
      user_id: userId,
      name: campaignData.name,
      type: campaignData.discount_type,
      max_payments: campaignData.max_payments,
      show_to_client: campaignData.show_to_client,
      send_notification: campaignData.send_notification,
      valid_from: startAtDate.toISOString(),
      valid_to: endAtDate.toISOString(),
    };

    if (campaignData.send_notification) {
      payload.template_id = campaignData.template_id;
      payload.channel_id = campaignData.channel_id;
      payload.transmitter_id = campaignData.transmitter_id;
      payload.notify_to = campaignData.notify_to;
      payload.notify_from = campaignData.notify_from;
    }

    if (campaignData.discount_type === 'compound_percent') {
      payload.principal_percent = campaignData.discount_principal_percent;
      payload.interest_percent = campaignData.discount_interest_percent;
      payload.past_due_interest_percent = campaignData.discount_past_due_interest_percent;
      payload.commission_percent = campaignData.discount_commission_percent;
    }

    if (campaignData.discount_type === 'total_percent') {
      payload.total_percent = campaignData.discount_total_percent;
    }

    if (campaignData.discount_type === 'amount') {
      payload.discount_total_amount = campaignData.discount_total_amount;
    }

    dataProvider
      .create('discount_offers', { data: payload })
      .then(() => {
        setSaving(false);
        notify('Discount offer successfully activated', 'success');
        onClose();
        onSubmit();
      })
      .catch(error => {
        setSaving(false);
        if (showConfirm) setShowConfirm(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const onSelectCampaign = id => setSelectedCampaign(id);

  const onShowCampaign = () => setShowCampaign(true);

  return suitableDiscountOffers.length === 0 ? (
    <NoOffersDialog onClose={onClose} />
  ) : (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
      <DialogTitle id="form-dialog-title">Manual discount offer activation</DialogTitle>
      {showCampaign ? (
        showConfirm ? (
          <>
            <DialogContent>
              <DialogContentText>
                ATTENTION!
                <br />
                There is an active discount offer on the loan.
                <br />
                When a new discount offer is created, <b>the current one will be canceled.</b>
                <br />
                <br />
                Do you want to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                No
              </Button>
              <Button
                onClick={createOffer}
                color="primary"
                variant="contained"
                startIcon={saving ? <CircularProgress size={20} thickness={1.5} /> : null}
                disabled={saving}>
                Yes
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              {!loadingData && <DialogContentText>Create discount offer:</DialogContentText>}
              {loadingData ? (
                <Box mt={5} display="flex" justifyContent="center" alignItems="center" sx={{ gap: '8px' }}>
                  <CircularProgress size={20} />
                  <Typography variant="body2">Loading campaign details ...</Typography>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  {campaignData && precalculation && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          label="Campaign name"
                          value={campaignData.name}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          label="Category of discount"
                          value={campaignData.discount_category}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          label="Number of payments"
                          value={campaignData.max_payments === 0 ? 'No limits' : campaignData.max_payments}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          label="End at"
                          fullWidth
                          disablePast
                          maxDate={timezone.shift(endAtDate)}
                          value={timezone.shift(endAtDate)}
                          onChange={value => setEndAtDate(timezone.unshift(value))}
                          helperText={`Max end date is: ${timezone.shift(endAtDate).toLocaleString()}`}
                        />
                      </Grid>
                      {campaignData.discount_type === 'compound_percent' && (
                        <Grid item xs={12}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                  <b>Actual debt, MXN</b>
                                </TableCell>
                                <TableCell>
                                  <b>Discount, %</b>
                                </TableCell>
                                <TableCell>
                                  <b>Debt after discount, MXN</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {campaignData.discount_category === 'loan_closing' && (
                                <TableRow>
                                  <TableCell>
                                    <b>Principal</b>
                                  </TableCell>
                                  <TableCell>{precalculation.accrued_principal}</TableCell>
                                  <TableCell>{precalculation.discount_principal_percent}</TableCell>
                                  <TableCell>{precalculation.principal_after_discount}</TableCell>
                                </TableRow>
                              )}
                              <TableRow>
                                <TableCell>
                                  <b>Past Due interest</b>
                                </TableCell>
                                <TableCell>{precalculation.accrued_past_due_interest}</TableCell>
                                <TableCell>{precalculation.discount_past_due_interest_percent}</TableCell>
                                <TableCell>{precalculation.past_due_interest_after_discount}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <b>Interest</b>
                                </TableCell>
                                <TableCell>{precalculation.accrued_interest}</TableCell>
                                <TableCell>{precalculation.discount_interest_percent}</TableCell>
                                <TableCell>{precalculation.interest_after_discount}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <b>Comission</b>
                                </TableCell>
                                <TableCell>{precalculation.accrued_commission}</TableCell>
                                <TableCell>{precalculation.discount_commission_percent}</TableCell>
                                <TableCell>{precalculation.commission_after_discount}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <b>Total</b>
                                </TableCell>
                                <TableCell>{precalculation.accrued_total}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{precalculation.amount_to_pay}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      )}
                      {campaignData.discount_type === 'amount' && (
                        <Grid item xs={12}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                  <b>Actual debt, MXN</b>
                                </TableCell>
                                <TableCell>
                                  <b>Discount, MXN</b>
                                </TableCell>
                                <TableCell>
                                  <b>Debt after discount, MXN</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <b>Total</b>
                                </TableCell>
                                <TableCell>{precalculation.accrued_total}</TableCell>
                                <TableCell>{precalculation.discount_amount}</TableCell>
                                <TableCell>{precalculation.amount_to_pay}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      )}
                      {campaignData.discount_type === 'total_percent' && (
                        <Grid item xs={12}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                  <b>Actual debt, MXN</b>
                                </TableCell>
                                <TableCell>
                                  <b>Discount, %</b>
                                </TableCell>
                                <TableCell>
                                  <b>Debt after discount, MXN</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <b>Total</b>
                                </TableCell>
                                <TableCell>{precalculation.accrued_total}</TableCell>
                                <TableCell>{precalculation.discount_percent}</TableCell>
                                <TableCell>{precalculation.amount_to_pay}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={campaignData.show_to_client} color="primary" />}
                          disabled
                          label="Display in private cabinet"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={campaignData.send_notification} color="primary" />}
                          disabled
                          label="Send notification"
                        />
                      </Grid>
                      {campaignData.send_notification && (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              variant="filled"
                              label="Template key"
                              value={campaignData.template_key}
                              disabled
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              variant="filled"
                              label="Transmitter"
                              value={campaignData.transmitter_id}
                              disabled
                              fullWidth
                            />
                          </Grid>
                          {campaignData.transmitter_id === 'cm' ? (
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                variant="filled"
                                label="Channel"
                                value={campaignData.channel_id}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} md={4}></Grid>
                          )}
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              variant="filled"
                              label="Sending time from"
                              value={campaignData.notify_from}
                              disabled
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              variant="filled"
                              label="Sending time till"
                              value={campaignData.notify_to}
                              disabled
                              fullWidth
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              {loadingCheck && (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: '8px' }}>
                  <CircularProgress size={20} />
                  <Typography variant="body2">Checking active offers ...</Typography>
                </Box>
              )}
              <Button
                onClick={() => {
                  setShowCampaign(false);
                  setPrecalculation(null);
                  setLoadingData(false);
                }}
                color="primary">
                Back
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={loadingData || loadingCheck || saving}
                startIcon={saving ? <CircularProgress size={20} thickness={1.5} /> : null}>
                Activate offer
              </Button>
            </DialogActions>
          </>
        )
      ) : (
        <SelectCampaignContent
          selectedCampaign={selectedCampaign}
          onClose={onClose}
          onSelectCampaign={onSelectCampaign}
          onShowCampaign={onShowCampaign}
          suitableDiscountOffers={suitableDiscountOffers}
        />
      )}
    </Dialog>
  );
};

AddManualDiscountDialog.propTypes = {
  suitableDiscountOffers: PropTypes.array,
  loanId: PropTypes.number,
  userId: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddManualDiscountDialog;
