import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import {
  Edit,
  TextInput,
  SelectInput,
  SaveButton,
  FormWithRedirect,
  required,
  BooleanInput,
  maxLength,
  useDataProvider,
  SelectArrayInput,
  NumberInput,
  Loading,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider, Paper, Checkbox, FormControlLabel, InputLabel, Button } from '@material-ui/core';
import { PHONE_PRIORITY_CHOICES } from '../../../utils/dictionary';
import { time as timeValidator } from '../../../utils/validator';
import UserPhonesDialog from './UserPhonesDialog';
import Autocomplete from '../../autocomplete/Autocomplete';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [transmitters, setTransmitters] = useState({});
  const [transmitter, setTransmitter] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const [variables, setVariables] = useState([]);
  const [dialerId, setDialerId] = useState(null);
  const [frequencyPeriod, setFrequencyPeriod] = useState(null);
  const [contactSource, setContactSource] = useState([]);
  const [contactType, setContactType] = useState([]);
  const [phoneStatus, setPhoneStatus] = useState(null);
  const [phoneValidation, setPhoneValidation] = useState([]);
  const [phoneContact, setPhoneContact] = useState(null);
  const [phonePrefix, setPhonePrefix] = useState(null);
  const [phoneWeight, setPhoneWeight] = useState([]);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  useEffect(() => {
    dataProvider.query('dialer_transmitters', { method: 'GET' }).then(res => {
      if (res) {
        let newData = {};

        for (const i of res.data) {
          let params = {};

          for (const j of i.params_description) {
            params = { ...params, [j.id]: j };
          }

          newData = { ...newData, [i.id]: { ...i, params_description: params } };
        }

        setTransmitters(newData);
      }
    });
  }, [dataProvider]);

  useEffect(() => {
    if (record.transmitter_id) {
      setTransmitter(record.transmitter_id);
    }

    if (record.params) {
      // eslint-disable-next-line react/prop-types
      const {
        dialer_id,
        frequency_period,
        contact_source,
        contact_type,
        phone_status,
        phone_validation,
        phone_contact,
        week_days,
        variables,
        phone_prefix,
        phone_weight,
      } = record.params;

      if (dialer_id) setDialerId(dialer_id);
      if (frequency_period) setFrequencyPeriod(frequency_period);
      if (contact_source) setContactSource(contact_source);
      if (contact_type) setContactType(contact_type);
      if (phone_status) setPhoneStatus(phone_status);
      if (phone_validation) setPhoneValidation(phone_validation);
      if (phone_contact) setPhoneContact(phone_contact);
      if (phone_prefix) setPhonePrefix(phone_prefix);
      if (week_days) setWeekDays(week_days);
      if (variables) setVariables(variables);
      if (phone_weight) setPhoneWeight(phone_weight);
    }
  }, [record]);

  const getAllowedValues = id => {
    if (transmitters?.[transmitter]?.params_description?.[id]?.allowed_values) {
      const { allowed_values } = transmitters?.[transmitter]?.params_description[id] || {};
      const values = Object.entries(allowed_values).map(item => ({
        id: item[1],
        [id === 'frequency_period' ||
        id === 'contact_source' ||
        id === 'contact_type' ||
        id === 'phone_status' ||
        id === 'phone_validation' ||
        id === 'phone_contact'
          ? 'name'
          : 'label']: item[0],
      }));

      return values;
    }
  };

  const onChange = (id, paramsId) => {
    const params = paramsId === 'week_days' ? weekDays : variables;
    const setParams = paramsId === 'week_days' ? setWeekDays : setVariables;
    const element = params.includes(id);

    if (element) {
      setParams(params.filter(i => i !== id));
    } else {
      setParams([...params, id]);
    }
  };

  const getParams = () => {
    const params = {};
    if (contactSource.length) {
      params.contact_source = contactSource;
    }
    if (contactType.length) {
      params.contact_type = contactType;
    }
    if (dialerId) {
      params.dialer_id = +dialerId;
    }
    if (frequencyPeriod) {
      params.frequency_period = frequencyPeriod;
    }
    if (phoneStatus) {
      params.phone_status = phoneStatus;
    }
    if (phoneValidation.length) {
      params.phone_validation = phoneValidation;
    }
    if (phoneContact) {
      params.phone_contact = phoneContact;
    }
    if (phonePrefix) {
      params.phone_prefix = phonePrefix;
    }
    if (variables.length) {
      params.variables = variables;
    }
    if (weekDays.length) {
      params.week_days = weekDays;
    }
    if (phoneWeight.length) {
      params.phone_weight = phoneWeight;
    }
    return params;
  };

  const handleShowUserPhones = () => {
    dataProvider
      .query(`dialer_campaigns/${record.id}/user_phones`, { method: 'GET' })
      .then(({ data }) => {
        const list = sortBy(Object.values(data.data), ['id']);
        setPreviewData(list);
        setIsPreviewDialogOpen(true);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  if (!Object.keys(transmitters).length) return <Loading />;

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Edit Dialer Base
          </Typography>
          <Divider className={classes.my2} />
          <FormWithRedirect
            save={(
              { name, start_time, end_time, mass_sending_audiences, is_enabled, is_clear_daily, transmitter_id },
              ...rest
            ) => {
              const payload = {
                name,
                start_time,
                end_time,
                mass_sending_audiences,
                is_enabled,
                is_clear_daily,
                transmitter_id,
                params: getParams(),
                _params: { method: 'PATCH' },
              };
              save(...[payload, ...rest]);
            }}
            {...props}
            render={formProps => {
              return (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} key="is_enabled">
                      <BooleanInput label="Enabled" source="is_enabled" initialValue={true} />
                    </Grid>
                    <Grid item xs={12} sm={6} key="is_clear_daily">
                      <BooleanInput label="Clear daily" source="is_clear_daily" initialValue={true} />
                    </Grid>
                    <Grid item xs={12} sm={6} key="name">
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6} key="start_time">
                      <TextInput
                        helperText="Format: hh:mm:ss"
                        source="start_time"
                        validate={[required(), value => timeValidator(value)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="end_time">
                      <TextInput
                        helperText="Format: hh:mm:ss"
                        source="end_time"
                        validate={[required(), value => timeValidator(value)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="mass_sending_audiences">
                      <Autocomplete
                        resource="mass_sending_audiences"
                        source="mass_sending_audiences"
                        optionValueProp="id"
                        optionLabelProp="name"
                        required
                        multiple
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="transmitter_id">
                      <SelectInput
                        disabled
                        helperText={false}
                        source="transmitter_id"
                        validate={[required()]}
                        choices={Object.keys(transmitters)?.map(key => ({ id: key, name: key }))}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} key="phone_weight">
                      <SelectArrayInput
                        allowEmpty
                        helperText={false}
                        source="params.phone_weight"
                        onChange={e => setPhoneWeight(e.target.value)}
                        label="Phone priority"
                        choices={PHONE_PRIORITY_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    {transmitter === 'webitel' && (
                      <>
                        <Grid item xs={12} sm={6} key="dialerID">
                          <NumberInput
                            source="params.dialer_id"
                            label="Dialer"
                            onChange={e => setDialerId(e.target.value)}
                            validate={[required(), maxLength(255)]}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="frequency_period">
                          <SelectInput
                            helperText={false}
                            onChange={e => setFrequencyPeriod(e.target.value)}
                            source="params.frequency_period"
                            label="Frequency period"
                            validate={[required()]}
                            choices={getAllowedValues('frequency_period')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_status">
                          <SelectInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setPhoneStatus(e.target.value)}
                            source="params.phone_status"
                            label="Phone status"
                            choices={getAllowedValues('phone_status')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="contact_type">
                          <SelectArrayInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setContactType(e.target.value)}
                            source="params.contact_type"
                            label="Contact type"
                            choices={getAllowedValues('contact_type')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="contact_source">
                          <SelectArrayInput
                            allowEmpty
                            source="params.contact_source"
                            label="Contact source"
                            helperText={false}
                            onChange={e => setContactSource(e.target.value)}
                            choices={getAllowedValues('contact_source')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_validation">
                          <SelectArrayInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setPhoneValidation(e.target.value)}
                            source="params.phone_validation"
                            label="Phone validation"
                            choices={getAllowedValues('phone_validation')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_contact">
                          <SelectInput
                            allowEmpty
                            helperText={false}
                            onChange={e => setPhoneContact(e.target.value)}
                            source="params.phone_contact"
                            label="Phone results"
                            choices={getAllowedValues('phone_contact')}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} key="phone_prefix">
                          <TextInput
                            helperText="Prefix with which to send numbers to Webitel (country code)"
                            source="params.phone_prefix"
                            label="Phone prefix"
                            onChange={e => setPhonePrefix(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} key="week_days">
                          <InputLabel id="week_days_label">Week days</InputLabel>
                          {getAllowedValues('week_days').map(day => (
                            <FormControlLabel
                              key={day.id}
                              control={
                                <Checkbox
                                  onChange={() => onChange(day.id, 'week_days')}
                                  checked={weekDays?.includes(day.id)}
                                  color="primary"
                                />
                              }
                              label={day.label}
                            />
                          ))}
                        </Grid>
                        <Grid item xs={12} sm={12} key="variables">
                          <InputLabel id="variables_label">Variables</InputLabel>
                          {getAllowedValues('variables').map(variable => (
                            <FormControlLabel
                              key={variable.id}
                              control={
                                <Checkbox
                                  onChange={() => onChange(variable.id, 'variables')}
                                  checked={variables?.includes(variable.id)}
                                  color="primary"
                                />
                              }
                              label={variable.label}
                            />
                          ))}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider className={classes.my2} />
                  <SaveButton
                    label="Submit"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    className={classes.mr2}
                  />
                  <Button variant="contained" color="primary" onClick={handleShowUserPhones}>
                    Show campaign clients list
                  </Button>
                  <UserPhonesDialog
                    previewList={previewData}
                    isOpened={isPreviewDialogOpen}
                    onClose={() => setIsPreviewDialogOpen(false)}
                  />
                </>
              );
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    id: PropTypes.number,
    transmitter_id: PropTypes.string,
    params: PropTypes.oneOfType([
      PropTypes.shape({
        dialer_id: PropTypes.number,
        variables: PropTypes.arrayOf(PropTypes.string),
        week_days: PropTypes.array,
        frequency_period: PropTypes.number,
        contact_source: PropTypes.arrayOf(PropTypes.number),
        contact_type: PropTypes.arrayOf(PropTypes.number),
        phone_status: PropTypes.number,
        phone_validation: PropTypes.arrayOf(PropTypes.number),
        phone_contact: PropTypes.number,
      }),
      PropTypes.array,
    ]),
  }),
};

const DialerBasesEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);
export default DialerBasesEdit;
