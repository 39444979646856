import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Autocomplete from '../autocomplete/Autocomplete';
import { SMS_TRANSMITTERS as smsTransmitters } from '../../utils/dictionary';

const useStyles = makeStyles(theme => ({
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const AddSmsDialog = ({ onClose, onSubmit, defaultPhoneNumber }) => {
  const classes = useStyles();
  const [switchValue, setSwitchValue] = useState('template');
  const [loading, setLoading] = useState(false);
  const [defaultSmsTransmitter, setDefaultSmsTransmitter] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .query('global_settings/by-name/default_sms_transmitter', {})
      .then(({ data }) => setDefaultSmsTransmitter(data.value))
      .catch(error => notify(`Error: ${error.message}`, 'error'));

    return () => setLoading(false);
  }, [dataProvider, notify]);

  const handleSwitchChange = (event, form) => {
    setSwitchValue(event.target.value);
    form.change('template_id', null);
    form.change('message', null);
  };

  const handleSubmit = values => {
    setLoading(true);
    const transmitterName = defaultSmsTransmitter ? smsTransmitters[defaultSmsTransmitter] : smsTransmitters['1'];
    const { destination, template_id, message } = values;
    onSubmit(destination, transmitterName, template_id, message);
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth size="lg">
      <DialogTitle id="form-dialog-title">Add user SMS</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ destination: defaultPhoneNumber }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field name="destination" validate={required('This field is required')}>
                    {({ input, meta }) => (
                      <TextField
                        fullWidth
                        label="Destination"
                        {...input}
                        error={meta.touched && meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="template message switch"
                      name="switch"
                      value={switchValue}
                      onChange={e => handleSwitchChange(e, form)}>
                      <FormControlLabel value="template" control={<Radio size="small" />} label="Use template" />
                      <FormControlLabel value="message" control={<Radio size="small" />} label="Use message" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {switchValue === 'template' && (
                  <Grid item xs={12}>
                    <Field name="template_id" validate={required('This field is required')}>
                      {({ input, meta }) => (
                        <Autocomplete
                          resource="templates"
                          source="template_id"
                          label="Template"
                          optionValueProp="id"
                          optionLabelProp="key"
                          filter={{ category: 'SMS', sending_type: 'manual', is_enabled: true }}
                          variant="standard"
                          margin="none"
                          size="medium"
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {switchValue === 'message' && (
                  <Grid item xs={12}>
                    <Field name="message" validate={required('This field is required')}>
                      {({ input, meta }) => (
                        <TextField
                          margin="none"
                          multiline
                          minRows="3"
                          fullWidth
                          label="Message"
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
              <DialogActions className={classes.mt4}>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : null}
                  color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddSmsDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultPhoneNumber: PropTypes.string,
};

AddSmsDialog.defaultProps = {
  defaultPhoneNumber: '',
};

export default AddSmsDialog;
