import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { Toolbar } from '@material-ui/core';

const TabPanelInternal = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}>
    <Box boxShadow={1}>{value === index ? children : null}</Box>
  </div>
);

TabPanelInternal.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: window.innerWidth < 1680 ? '1596px' : 'calc(100vw - 330px)',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    minWidth: 'auto',
  },
  my1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const TabPanel = ({ label, labels = [], children, activeTabIndex }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(activeTabIndex || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!children) {
    return null;
  }

  const tabs = children
    .map((item, idx) =>
      item
        ? {
            label: labels[idx] || `Item-${idx}`,
            element: item,
          }
        : null,
    )
    .filter(item => !!item);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar variant="dense" disableGutters>
          {label && (
            <Typography variant="h6" component="div" style={{ padding: '0 16px' }}>
              {label}:
            </Typography>
          )}
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs">
            {tabs.map(({ label }, idx) => {
              const labelText = label.split('|')[0];
              const labelNum = label.split('|')[1] ? +label.split('|')[1] : null;
              const tabLabel =
                labelNum === null ? (
                  labelText
                ) : (
                  <Badge badgeContent={Number(label.split('|')[1])} color="error" overlap="rectangular">
                    {labelText}
                  </Badge>
                );

              return (
                <Tab
                  key={`tab-${idx}`}
                  className={classes.tab}
                  label={tabLabel}
                  style={{ fontSize: 12, paddingLeft: 8, paddingRight: 8 }}
                  {...a11yProps(idx)}
                />
              );
            })}
          </Tabs>
        </Toolbar>
      </AppBar>
      <div className={classes.my1}>
        {tabs.map(({ element }, idx) => (
          <TabPanelInternal key={`panel-${idx}`} value={value} index={idx}>
            {element}
          </TabPanelInternal>
        ))}
      </div>
    </div>
  );
};

TabPanel.propTypes = {
  label: PropTypes.string,
  labels: PropTypes.array,
  children: PropTypes.node,
  activeTabIndex: PropTypes.number,
};

export default TabPanel;
