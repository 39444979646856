import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { formatDatetime } from '../../utils/formatter';
import DeleteMessageConfirmDialog from './DeleteMessageConfirmDialog';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  danger: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  deleted: {
    backgroundColor: grey[200],
    '& .MuiTableCell-body': {
      color: grey[500],
    },
    '& .MuiChip-root': {
      color: grey[500],
    },
  },
  maxWidth350: {
    maxWidth: '350px',
  },
}));

const AccountMessageTable = ({ userId, loanId, refreshedAt, onRefresh }) => {
  const [messages, setMessages] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [isDeleteMessageDialogOpened, setIsDeleteMessageDialogOpened] = useState(false);
  const [itemId, setItemId] = useState(null);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  useEffect(() => {
    const filter = loanId ? { 'user.id': userId, 'loan.id': loanId } : { 'user.id': userId };
    dataProvider
      .getList('user_notifications', {
        filter,
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setMessages(data);
        setTotal(total);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, userId, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(messages)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Closeable</TableCell>
            <TableCell align="left">Message meta</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map(row => (
            <TableRow key={row.id} className={row.deleted_at ? classes.deleted : undefined}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.status} />
              </TableCell>
              <TableCell className={classes[row.type]}>{row.type}</TableCell>
              <TableCell className={classes.maxWidth350}>
                <b>Text: </b>
                {row.text}
                <br />
                <b>Telegram: </b>
                {row.telegram_link_url || 'N/A'}
                <br />
                <b>WhatsApp: </b>
                {row.whatsapp_link_url || 'N/A'}
                <br />
                <b>Phone: </b>
                {row.phone_number || 'N/A'}
              </TableCell>
              <TableCell>
                {row.can_be_closed ? (
                  <DoneIcon color="secondary" fontSize="small" />
                ) : (
                  <CloseIcon color="secondary" fontSize="small" />
                )}
              </TableCell>
              <TableCell align="left">
                <b>Created: </b>
                {row.created_at && formatDatetime(row.created_at)}
                <br />
                <b>Read: </b>
                {row.read_at && formatDatetime(row.read_at)}
                <br />
                <b>Closed: </b>
                {row.closed_at && formatDatetime(row.closed_at)}
                <br />
                <b>Deleted: </b>
                {row.deleted_at && formatDatetime(row.deleted_at)}
                <br />
                <b>Deleted by: </b>
                {row.deleted_by_name}
              </TableCell>
              <TableCell align="right">
                <ButtonGroup
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!permissions.includes('CAN_USER_NOTIFICATION_EDIT') || row.deleted_at}>
                  <Button
                    disabled={!permissions.includes('CAN_USER_NOTIFICATION_EDIT') || row.deleted_at}
                    onClick={() => {
                      if (row.id) {
                        setItemId(row.id);
                      }
                      setIsDeleteMessageDialogOpened(true);
                    }}>
                    Delete
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      {isDeleteMessageDialogOpened && (
        <DeleteMessageConfirmDialog
          title="Delete message"
          message="Are you sure want to delete this message?"
          onClose={() => {
            setItemId(null);
            setIsDeleteMessageDialogOpened(false);
          }}
          onConfirm={() => {
            setIsDeleteMessageDialogOpened(false);
            dataProvider
              .delete('user_notifications', { id: itemId })
              .then(() => {
                setItemId(null);
                notify('Message deleted', 'success');
                onRefresh();
              })
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
        />
      )}
    </div>
  );
};

AccountMessageTable.propTypes = {
  userId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onRefresh: PropTypes.func,
};

export default AccountMessageTable;
