import React from 'react';
import {
  Create,
  TextInput,
  NumberInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  required,
  maxLength,
  minValue,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { integerValidator } from '../../../utils/validator';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Create new collection group
                    </Typography>
                    <BooleanInput
                      source="is_repeat"
                      label="Is repeat?"
                      helperText={false}
                      defaultValue={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="code" label="Group code" validate={[maxLength(60), required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="ptp_term"
                        label="Max PTP term"
                        min={0}
                        validate={[integerValidator(), minValue(0, 'The value must be a positive number'), required()]}
                        helperText={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="dpd_from"
                        label="Days past due from"
                        validate={[required()]}
                        helperText={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="dpd_to"
                        label="Days past due to"
                        validate={[required()]}
                        helperText={false}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    transform={data => ({ ...data })}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const CollectionGroupCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
export default CollectionGroupCreate;
