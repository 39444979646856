import React from 'react';
import PropTypes from 'prop-types';
import { useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { formatDatetime } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  container: {
    borderBottom: `1px solid ${grey[300]}`,
    padding: '6px 16px',
  },
  flexBoxCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px',
  },
  unread: {
    backgroundColor: blue[100],
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

// eslint-disable-next-line unused-imports/no-unused-vars
const AdminNotification = React.forwardRef(({ notification, onClose, onRead }, ref) => {
  const { id, loan_id, trigger, read_at, created_at, income_payment_type, income_amount } = notification;
  const classes = useStyles();
  const redirect = useRedirect();

  const isUnread = read_at === null;

  const getNotificationText = () => {
    switch (trigger) {
      case 'income':
        return `Loan #${loan_id} Payment`;
      case 'login':
        return `Loan #${loan_id} Login to personal area (Login)`;
      case 'refresh_token':
        return `Loan #${loan_id} Login to personal area (Refresh token)`;
      case 'login_by_contract_number':
        return `Loan #${loan_id} Payment page (Contract number)`;
      case 'login_by_phone':
        return `Loan #${loan_id} Payment page (Phone number)`;
      case 'login_by_short_link_personal_account':
        return `Loan #${loan_id} Login by short link (Personal area)`;
      case 'login_by_short_link_extension':
        return `Loan #${loan_id} Login by short link (Extension)`;
      case 'promise_payment_date':
        return `Loan #${loan_id} Promise date`;
      case 'repeat_communication_reminder_date':
        return `Loan #${loan_id} Communication reminder`;
      case 'related_project_loan_closed':
        return `Loan #${loan_id} Repaid loan in another company`;
      default:
        return `Loan #${loan_id} Undefined action`;
    }
  };

  const handleItemRead = () => {
    onRead(id);
  };

  const handleItemOpen = () => {
    onClose();
    isUnread && onRead(id);
    redirect(`/loans/${loan_id}/show`);
  };

  const itemClasses = isUnread ? [classes.container, classes.unread].join(' ') : classes.container;

  return (
    <li className={itemClasses}>
      <Box className={classes.flexBoxRow}>
        <Box className={classes.flexBoxCol}>
          <Typography variant="body2">{getNotificationText(trigger)}</Typography>
          {trigger === 'income' && (
            <Typography variant="caption">
              {income_payment_type}
              {': '}
              <b>${income_amount}</b>
            </Typography>
          )}
          <Typography variant="caption">{formatDatetime(created_at)}</Typography>
        </Box>
        <Box className={classes.flexBoxRow}>
          {isUnread && (
            <IconButton className={classes.button} size="small" onClick={handleItemRead}>
              <VisibilityIcon fontSize="inherit" />
            </IconButton>
          )}
          <IconButton className={classes.button} size="small" onClick={handleItemOpen}>
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    </li>
  );
});

AdminNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    loan_id: PropTypes.number,
    trigger: PropTypes.string,
    read_at: PropTypes.string,
    created_at: PropTypes.string,
    income_amount: PropTypes.number,
    income_payment_type: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onRead: PropTypes.func,
};

export default AdminNotification;
