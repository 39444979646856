import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  legend: {
    marginBottom: 6,
  },
  resetButton: {
    alignSelf: 'center',
    padding: 0,
  },
  label: {
    marginRight: 10,

    '& .MuiTypography-root': {
      fontSize: '0.75rem',
    },

    '& .MuiIconButton-root': {
      padding: 4,
    },
  },
}));

const ExclusiveFourOptInput = ({ firstOption, secondOption, thirdOption, fourthOption, label, resettable = false }) => {
  const [value, setValue] = React.useState('');

  const classes = useStyles();

  const option_first = useField(firstOption.source);
  const option_second = useField(secondOption.source);
  const option_three = useField(thirdOption.source);
  const option_four = useField(fourthOption.source);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleReset = () => {
    setValue('');
  };

  React.useEffect(() => {
    if (value === firstOption.source) {
      option_first.input.onChange(true);
      option_second.input.onChange(null);
      option_three.input.onChange(null);
      option_four.input.onChange(null);
    } else if (value === secondOption.source) {
      option_first.input.onChange(null);
      option_second.input.onChange(true);
      option_three.input.onChange(null);
      option_four.input.onChange(null);
    } else if (value === thirdOption.source) {
      option_first.input.onChange(null);
      option_second.input.onChange(null);
      option_three.input.onChange(true);
      option_four.input.onChange(null);
    } else if (value === fourthOption.source) {
      option_first.input.onChange(null);
      option_second.input.onChange(null);
      option_three.input.onChange(null);
      option_four.input.onChange(true);
    } else {
      option_first.input.onChange(null);
      option_second.input.onChange(null);
      option_three.input.onChange(null);
      option_four.input.onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.legend}>
          {label}
        </FormLabel>
        <RadioGroup row name="gender1" value={value} onChange={handleChange}>
          <FormControlLabel
            className={classes.label}
            value={firstOption.source}
            control={<Radio size="small" />}
            label={firstOption.label}
          />
          <FormControlLabel
            className={classes.label}
            value={secondOption.source}
            control={<Radio size="small" />}
            label={secondOption.label}
          />
          <FormControlLabel
            className={classes.label}
            value={thirdOption.source}
            control={<Radio size="small" />}
            label={thirdOption.label}
          />
          <FormControlLabel
            className={classes.label}
            value={fourthOption.source}
            control={<Radio size="small" />}
            label={fourthOption.label}
          />
        </RadioGroup>
      </FormControl>
      {resettable && (
        <Tooltip title="Reset selection">
          <IconButton size="small" className={classes.resetButton} onClick={handleReset} edge="end">
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

ExclusiveFourOptInput.propTypes = {
  firstOption: PropTypes.shape({
    source: PropTypes.string,
    label: PropTypes.string,
  }),
  secondOption: PropTypes.shape({
    source: PropTypes.string,
    label: PropTypes.string,
  }),
  thirdOption: PropTypes.shape({
    source: PropTypes.string,
    label: PropTypes.string,
  }),
  fourthOption: PropTypes.shape({
    source: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.string,
  resettable: PropTypes.bool,
};

export default ExclusiveFourOptInput;
