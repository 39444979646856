import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { green, orange, red } from '@material-ui/core/colors';
import { formatDatetime, formatCurrency } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  promiseOfPayment: {
    margin: 4,
    padding: 16,
    backgroundColor: '#FFCCCC',
    borderRadius: 4,
    fontWeight: 700,
  },
  redRow: {
    backgroundColor: '#FFCCCC',
  },
  orangeChip: {
    backgroundColor: orange[500],
  },
  greenChip: {
    backgroundColor: green[500],
  },
  greenLightChip: {
    backgroundColor: green[200],
  },
  redChip: {
    backgroundColor: red[500],
  },
}));

const PtpCollectionTable = ({ loanId, refreshedAt, brokenPtpCounter }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [promises, setPromises] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('promise_to_pays', {
        filter: { 'loan.id': loanId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setPromises(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  const getPtpStatusColor = status => {
    switch (status) {
      case 'in_progress':
        return classes.orangeChip;
      case 'kept':
        return classes.greenChip;
      case 'partially_kept':
        return classes.greenLightChip;
      case 'broken':
        return classes.redChip;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(promises)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      {brokenPtpCounter > 0 && (
        <div className={classes.promiseOfPayment}>{`Client has ${brokenPtpCounter} broken promises`}</div>
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>PTP created</TableCell>
            <TableCell>Collector</TableCell>
            <TableCell>PTP date</TableCell>
            <TableCell>PTP Amount</TableCell>
            <TableCell>Paid amount</TableCell>
            <TableCell>PTP status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promises.map(row => (
            <TableRow key={row.id} className={row.status === 'broken' && classes.redRow}>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
              <TableCell>{row.collector_name}</TableCell>
              <TableCell>{row.promise_date && formatDatetime(row.promise_date)}</TableCell>
              <TableCell>{row.promise_amount && formatCurrency(row.promise_amount)}</TableCell>
              <TableCell>{row.paid_amount && formatCurrency(row.paid_amount)}</TableCell>
              <TableCell>
                <Chip size="small" label={row.status} className={getPtpStatusColor(row.status)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

PtpCollectionTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  brokenPtpCounter: PropTypes.number,
};

export default PtpCollectionTable;
