import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency, formatDate } from '../../utils/formatter';
import { useProducts } from '../../hooks/useProducts';
import Autocomplete from '../autocomplete/Autocomplete';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

const UserLoanApplicationDialog = ({ onClose, record }) => {
  const [productId, setProductId] = useState(null);
  const [principal, setPrincipal] = useState(1e4);
  const [displayedPrincipal, setDisplayedPrincipal] = useState(1e4);
  const [tenor, setTenor] = useState(10);
  const [displayedTenor, setDisplayedTenor] = useState(10);
  const [targetUrl, setTargetUrl] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [priceList, setPriceList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tenors, setTenors] = useState([]);
  const [principals, setPrincipals] = useState([]);
  const [calculation, setCalculation] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const { data: products } = useProducts();

  const isRepeatedLoan = record?.number_of_closed_loans !== 0;
  const activeProducts = products.filter(product => product.isDisabled === false);
  const selectedProduct = products.length > 0 ? products.find(product => productId === product.id) : null;

  useEffect(() => {
    if (!productId && products.length > 0) {
      const firstActiveProduct = products.find(
        product => product.isDisabled === false && product.code === record.preferred_product_code,
      );

      if (firstActiveProduct) {
        const minPrincipal = isRepeatedLoan ? firstActiveProduct.principal.min : firstActiveProduct.principal.first_min;
        const minTenor = isRepeatedLoan ? firstActiveProduct.tenor.min : firstActiveProduct.tenor.first_min;
        setProductId(firstActiveProduct.id);
        setPrincipal(minPrincipal > 1e4 ? minPrincipal : 1e4);
        setDisplayedPrincipal(minPrincipal > 1e4 ? minPrincipal : 1e4);
        setTenor(minTenor > 10 ? minTenor : 10);
        setDisplayedTenor(minTenor > 10 ? minTenor : 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, products, isRepeatedLoan]);

  useEffect(() => {
    if (priceList && tenor && principal) {
      const calculation = priceList && priceList[tenor] && priceList[tenor][principal];
      setCalculation(calculation);
    }
  }, [priceList, tenor, principal]);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setPriceList(null);
    if (!productId) {
      setLoading(false);
      return;
    }
    dataProvider
      .query(
        `p/product-price-list/${selectedProduct.code}?is_repeated_loan=${isRepeatedLoan ? 1 : 0}`.concat(
          promoCode ? '&promo_code='.concat(encodeURIComponent(promoCode)) : '',
        ),
        { method: 'GET' },
      )
      .then(({ data }) => {
        const tenors = Object.keys(data);
        const tenorsValues = Object.values(data);
        const principals = Object.keys(tenorsValues[0]);
        const minPrincipal = isRepeatedLoan ? selectedProduct.principal.min : selectedProduct.principal.first_min;
        const minTenor = isRepeatedLoan ? selectedProduct.tenor.min : selectedProduct.tenor.first_min;
        setPriceList(data);
        setTenors(tenors);
        setPrincipals(principals);
        setDisplayedPrincipal(minPrincipal > 1e4 ? minPrincipal : 1e4);
        setDisplayedTenor(minTenor > 10 ? minTenor : 10);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, productId, promoCode, isRepeatedLoan]);

  const handleSubmitApplication = values => {
    const payload = {
      product_code: selectedProduct.code,
      principal,
      tenor,
      target_url: targetUrl,
      promo_code: values.promo_code ?? null,
    };

    dataProvider
      .query(`users/${record.id}/application`, { body: JSON.stringify(payload) })
      .then(({ data: { id } }) => redirect(`/applications/${id}/show`))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create loan application</DialogTitle>
      <DialogContent>
        <DialogContentText>To create loan application, please fill following fields</DialogContentText>
        <Form
          onSubmit={handleSubmitApplication}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="productId-select-label">Product</InputLabel>
                <Select
                  key={`user-edit-products-input-${products.length}`}
                  labelId="productId-select-label"
                  value={productId || ''}
                  onChange={e => {
                    setProductId(e.target.value);
                    const product = products.find(product => e.target.value === product.id);
                    if (product) {
                      setPrincipal(
                        Math.round(
                          Math.min(Math.max(product.principal.min, principal), product.principal.max) /
                            product.principal.step,
                        ) * product.principal.step,
                      );
                      setTenor(
                        Math.round(Math.min(Math.max(product.tenor.min, 10), product.tenor.max) / product.tenor.step) *
                          product.tenor.step,
                      );
                    }
                  }}>
                  {activeProducts.map(product => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography className={classes.mt2} id="principal-slider" gutterBottom>
                Principal: {`${displayedPrincipal / 1e3}k`}
              </Typography>
              <Slider
                defaultValue={displayedPrincipal}
                value={displayedPrincipal}
                valueLabelFormat={value => `${value / 1e3}k`}
                aria-labelledby="principal-slider"
                valueLabelDisplay="auto"
                step={selectedProduct?.principal.step || 1000}
                marks
                min={+principals[0] || 2000}
                max={+principals[principals.length - 1] || 50000}
                onChangeCommitted={(ev, value) => setPrincipal(value)}
                onChange={(ev, value) => {
                  setDisplayedPrincipal(value);
                  setPromoCode(null);
                  form.change('promo_code', null);
                }}
              />
              <Typography id="tenor-slider" gutterBottom>
                Tenor: {displayedTenor} days
              </Typography>
              <Slider
                defaultValue={displayedTenor}
                value={displayedTenor}
                aria-labelledby="tenor-slider"
                valueLabelDisplay="auto"
                step={selectedProduct?.tenor.step || 1}
                marks
                min={+tenors[0] || 10}
                max={+tenors[tenors.length - 1] || 30}
                onChangeCommitted={(ev, value) => setTenor(value)}
                onChange={(ev, value) => {
                  setDisplayedTenor(value);
                  setPromoCode(null);
                  form.change('promo_code', null);
                }}
              />
              <TextField
                margin="dense"
                label="Target URL"
                type="text"
                fullWidth
                onChange={e => setTargetUrl(e.target.value)}
              />
              <Autocomplete
                resource="promo_codes"
                source="promo_code"
                optionValueProp="code"
                optionLabelProp="code"
                variant="standard"
                filter={{ is_enabled: true }}
                helperText="If you can not choose any of the promo codes - it means that some conditions for its application are not met"
                onFieldChange={e => {
                  setPromoCode(e.target.textContent || null);
                }}
                disableParams={{ principal, tenor, isRepeatedLoan }}
              />
              <Typography className={classes.mt2} variant="h6" component="div">
                Calculation preview
              </Typography>
              {calculation ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Discount</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Loan amount
                        </TableCell>
                        <TableCell align="right">{formatCurrency(calculation.principal)}</TableCell>
                        <TableCell align="right">{formatCurrency(calculation.discount.principal)}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(calculation.principal - calculation.discount.principal)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Interest
                        </TableCell>
                        <TableCell align="right">{formatCurrency(calculation.interest)}</TableCell>
                        <TableCell align="right">{formatCurrency(calculation.discount.interest)}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(calculation.interest - calculation.discount.interest)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Comission
                        </TableCell>
                        <TableCell align="right">{formatCurrency(calculation.commission)}</TableCell>
                        <TableCell align="right">{formatCurrency(calculation.discount.commission)}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(calculation.commission - calculation.discount.commission)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <b>Settlement amount</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{formatCurrency(calculation.settlement_amount)}</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{formatCurrency(calculation.discount.settlement_amount)}</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>
                            {formatCurrency(calculation.settlement_amount - calculation.discount.settlement_amount)}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Payment date
                        </TableCell>
                        <TableCell align="right" colSpan={3}>
                          {formatDate(calculation.maturity_date)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
              {loading ? <CircularProgress /> : null}
              {error ? (
                <Typography variant="subtitle1" color="error">
                  {error.message}
                </Typography>
              ) : null}
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

UserLoanApplicationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  record: PropTypes.object,
};

export default UserLoanApplicationDialog;
