import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

import { formatDatetime } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  fixedWidth: {
    width: '220px',
  },
  tableHead: {
    '& .MuiTableCell-sizeSmall': {
      padding: '5px',
    },
  },
  tableBody: {
    '& .MuiTableCell-sizeSmall': {
      padding: '5px',
    },
  },
}));

const MailTable = ({ userId = null, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [mails, setMails] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('emails', {
        filter: {
          ...(userId ? { 'communication.user': userId } : {}),
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setMails(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(mails)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Delivery</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell>Metadata</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {mails.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell className={classes.breakableCell}>{row.from_email}</TableCell>
              <TableCell className={[classes.breakableCell, classes.fixedWidth].join(' ')}>
                {row.to_email.map(email => (
                  <div key={email}>{email}</div>
                ))}
              </TableCell>
              <TableCell className={classes.breakableCell}>{row.subject}</TableCell>
              <TableCell className={[classes.breakableCell, classes.fixedWidth].join(' ')}>{row.preview}</TableCell>
              <TableCell>
                <Chip size="small" label={row.communication.state} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.delivery_status} />
              </TableCell>
              <TableCell>
                <b>Admin: </b>
                {row.communication.admin?.username || 'N/A'}
                <br />
                <b>Result: </b>
                {row.communication.result?.name || 'N/A'}
                <br />
                <b>Category: </b>
                {row.communication.category?.name || 'N/A'}
              </TableCell>
              <TableCell>
                <b>Created at: </b>
                {row.created_at ? formatDatetime(row.created_at) : 'N/A'}
                <br />
                <b>Read at: </b>
                {row.read_at ? formatDatetime(row.read_at) : 'N/A'}
              </TableCell>
              <TableCell>
                <Link
                  href={`#/${row.communication.direction}/${row.id}`}
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                  <IconButton onClick={e => e.stopPropagation()}>
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

MailTable.propTypes = {
  userId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
};

export default MailTable;
