import React from 'react';
import { useField } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from '../styles';
import ReportDate from '../components/ReportDate';
import ReportDateFormat from '../components/ReportDateFormat';
import ReportTimezone from '../components/ReportTimezone';

const AffiliateCreditsParamsInput = () => {
  const classes = useStyles();

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField('affiliate_credits');

  return (
    <>
      <Grid container spacing={2}>
        <ReportDate classes={classes} value={value} touched={touched} error={error} onChange={onChange} />
      </Grid>
      <Grid container spacing={4}>
        <ReportTimezone classes={classes} value={value} touched={touched} error={error} onChange={onChange} required />
        <ReportDateFormat
          classes={classes}
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          required
        />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.fullWidth} error={!!(touched && error && error.filter_by_date)} required>
            <InputLabel id="sorton-select-label">Filter by date</InputLabel>
            <Select
              labelId="sorton-select-label"
              value={value && value.filter_by_date ? value.filter_by_date : ''}
              onChange={e => onChange({ ...(value || {}), filter_by_date: e.target.value })}>
              <MenuItem value="loan_disbursed_date">Loan disbursed date</MenuItem>
              <MenuItem value="application_created_date">Application created date</MenuItem>
            </Select>
            {touched && error && error.filter_by_date && <FormHelperText>{error.filter_by_date}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default AffiliateCreditsParamsInput;
