import * as Sentry from '@sentry/react';

import { getApiEntrypoint } from './utils/env';

export default {
  login: ({ username, password }) => {
    const apiEntrypoint = getApiEntrypoint();
    const request = new Request(apiEntrypoint + '/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-AUTH-CTX': 'admin',
      }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return response.json().then(({ message }) => {
            throw new Error(message || response.statusText);
          });
        }
        return response.json();
      })
      .then(({ user, token, expire_at }) => {
        localStorage.setItem('admin_fields', JSON.stringify(user));
        localStorage.setItem('admin_access_token', token);
        localStorage.setItem('admin_access_token_expire_at', expire_at);
        localStorage.setItem('checked_collection_loans', JSON.stringify([]));
        Sentry.setUser({ id: user.id, username: user.username });
        return Promise.resolve();
      });
  },
  logout: () => {
    localStorage.removeItem('admin_fields');
    localStorage.removeItem('admin_access_token');
    localStorage.removeItem('admin_access_token_expire_at');
    localStorage.removeItem('checked_collection_loans');
    Sentry.setUser(null);
    return Promise.resolve();
  },
  checkAuth: () => {
    if (
      localStorage.getItem('admin_access_token') &&
      new Date(localStorage.getItem('admin_access_token_expire_at')) > new Date()
    ) {
      return Promise.resolve();
    } else {
      localStorage.removeItem('checked_collection_loans');
      return Promise.reject(new Error('token expired'));
    }
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('admin_fields');
      localStorage.removeItem('admin_access_token');
      return Promise.reject(new Error(status));
    }
    return Promise.resolve();
  },
  getPermissions: () =>
    new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('admin_fields'));
      if (user && user.roles) {
        resolve(user.roles);
      } else {
        reject(new Error('user not exist'));
      }
    }),
};
