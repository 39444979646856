import { makeStyles } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';

export const useReportStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  formControl400: {
    margin: theme.spacing(1),
    width: 400,
  },
  formControl200: {
    margin: theme.spacing(1),
    width: 200,
  },
  orange: {
    backgroundColor: orange[300],
  },
  green: {
    backgroundColor: green[300],
  },
  red: {
    backgroundColor: red[300],
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      width: '75px',
    },
  },
  tableContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 'none',
    borderRight: 'none',
    marginTop: theme.spacing(2),
  },
  fontBold: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
    },
  },
}));

export const generateActionsQuery = (collectionGroup, collectors, endpoint, filterQuery, filter, maxDpd, minDpd) => {
  const groupsQuery =
    collectionGroup.length > 0 ? collectionGroup.map(g => `collection_group_list[]=${g}`).join('&') : null;
  const collectorsQuery = collectors.length > 0 ? collectors.map(c => `collector_id_list[]=${c}`).join('&') : null;
  const typeFilter = `${filterQuery}=${filter}`;
  const maxDpdFilter = maxDpd ? `days_past_due[lte]=${+maxDpd}` : null;
  const minDpdFilter = minDpd ? `days_past_due[gte]=${+minDpd}` : null;
  let query = collectorsQuery
    ? `${endpoint}?${typeFilter}&${collectorsQuery}`
    : `${endpoint}?${typeFilter}&${groupsQuery}`;
  if (maxDpdFilter) query = query.concat(`&${maxDpdFilter}`);
  if (minDpdFilter) query = query.concat(`&${minDpdFilter}`);

  return query;
};
