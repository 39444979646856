import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';
import snakeToHuman from '../../../utils/snakeToHuman';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '5px',
  },
}));

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const CollectionAlertsSettingsList = props => {
  const classes = useStyles();

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <FunctionField
          label="Event name"
          render={record => <Chip size="small" label={snakeToHuman(record.trigger)} />}
        />
        <FunctionField
          label="Collection groups"
          render={record => (
            <div className={classes.flex}>
              {record.groups?.map(group => (
                <Chip key={group} size="small" label={group} />
              ))}
            </div>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default CollectionAlertsSettingsList;
