import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  CheckboxGroupInput,
  Edit,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  SelectArrayInput,
  TextInput,
  useDataProvider,
  useNotify,
  NumberInput,
  minValue,
  maxValue,
} from 'react-admin';
import DateFnsAdapter from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { DAYS_MODE_DOM, DAYS_MODE_DOW, MONTH_DAYS, WEEK_DAYS } from '../../../constants';
import DatePickerInput from '../../input/DatePickerInput';
import { useHandbook } from '../../../hooks/useHandbook';

import { dateValidation, getFormStyles } from './constants';
import Autocomplete from '../../autocomplete/Autocomplete';

const useStyles = makeStyles(theme => getFormStyles(theme));

const FormWrapper = ({ save, record, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dateFns = new DateFnsAdapter();

  // const getAllocationPeriod = () => {
  //   if (!record.params.allocated_days && !record.params.allocated_date) {
  //     return 1;
  //   }
  //   if (record.params.allocated_days) {
  //     return 2;
  //   }
  //   if (record.params.allocated_date) {
  //     return 3;
  //   }
  // };

  const [daysMode, setDaysMode] = useState(
    Array.isArray(record.params.month_days) && record.params.month_days?.length > 0
      ? DAYS_MODE_DOM
      : record.params.week_days?.length > 0
        ? DAYS_MODE_DOW
        : 'onetime',
  );
  const [strategiesList, setStrategiesList] = useState([]);
  const [isAdhoc, setIsAdhoc] = useState(false);
  const [amountSelected, setAmountSelected] = useState(record.params.amount);
  const [isCloseAfterPayPrincipalSelected, setIsCloseAfterPayPrincipalSelected] = useState(
    record.params.close_after_pay_principal,
  );
  const [isTemplateSelected, setIsTemplateSelected] = useState(Boolean(record.template_id));

  const { data: banks } = useHandbook('banks');

  useEffect(() => {
    dataProvider
      .query('auto_debit_strategies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setStrategiesList(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  useEffect(() => {
    setIsAdhoc(record.is_adhoc);
  }, [record]);

  const STRATEGY_CHOICES = strategiesList?.map(item => ({ id: item.id, name: item.title }));
  const weekDaysChoices = WEEK_DAYS.map((value, index) => ({ id: index + 1, name: value }));
  const monthDaysChoices = MONTH_DAYS.map(item => ({ id: item.value.toString(), name: item.label.toString() }));
  const amountChoices = isCloseAfterPayPrincipalSelected
    ? [
        { id: 'full', name: 'Full', disabled: true },
        { id: 'principal', name: 'Principal' },
        { id: 'principal_and_amount', name: 'Principal and amount', disabled: true },
        { id: 'principal_and_percent', name: 'Principal and percent', disabled: true },
        { id: 'pay_amount', name: 'Pay amount', disabled: true },
        { id: 'pay_amount_lower_then_principal', name: 'Pay amount lower then principal', disabled: true },
      ]
    : [
        { id: 'full', name: 'Full' },
        { id: 'principal', name: 'Principal' },
        { id: 'principal_and_amount', name: 'Principal and amount' },
        { id: 'principal_and_percent', name: 'Principal and percent' },
        { id: 'pay_amount', name: 'Pay amount' },
        { id: 'pay_amount_lower_then_principal', name: 'Pay amount lower then principal' },
      ];

  return (
    <FormWithRedirect
      save={(
        {
          audience_id,
          strategy_id,
          name,
          is_enabled,
          is_adhoc,
          bank_account_type,
          one_time_allocations,
          write_off_only_with_success,
          template_id,
          params,
        },
        ...rest
      ) => {
        save(
          ...[
            {
              name,
              audience_id,
              strategy_id: strategy_id ?? null,
              is_enabled,
              is_adhoc,
              bank_account_type,
              write_off_only_with_success,
              template_id: template_id ?? null,
              params: {
                week_days: params.week_days,
                month_days: params.month_days?.length ? params.month_days.map(day => +day) : [],
                one_time_allocations:
                  daysMode === 'onetime' ? [dateFns.format(new Date(one_time_allocations), 'yyyy-MM-dd')] : null,
                amount: params.amount ?? 'full',
                add_amount: params.add_amount ?? null,
                add_percent: params.add_percent ?? null,
                banks: params.banks,
                close_after_pay_principal: params.close_after_pay_principal,
                send_template: params.send_template,
              },
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6" gutterBottom={false}>
                      Update auto debit campaign
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput label="Enabled" source="is_enabled" />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      resource="mass_sending_audiences"
                      source="audience_id"
                      optionValueProp="id"
                      optionLabelProp="name"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} key="bank_account_type">
                    <SelectInput
                      label="Bank account type"
                      helperText={false}
                      source="bank_account_type"
                      validate={[required()]}
                      choices={[
                        { id: 'any', name: 'Any' },
                        { id: 'main', name: 'Main' },
                        { id: 'alternative', name: 'Alternative' },
                      ]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} key="is_adhoc">
                    <SelectInput
                      label="Is Adhoc?"
                      helperText={false}
                      source="is_adhoc"
                      validate={[required()]}
                      choices={[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' },
                      ]}
                      fullWidth
                      onChange={e => {
                        setIsAdhoc(e.target.value);
                        formProps.form.change('strategy_id', null);
                      }}
                    />
                  </Grid>
                  {isAdhoc && (
                    <Grid item xs={12} sm={6} key="strategy_id">
                      <SelectInput source="strategy_id" resettable choices={STRATEGY_CHOICES} fullWidth />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <BooleanInput
                      label="Use bank accounts from which there has been at least one successful debit"
                      source="write_off_only_with_success"
                      helperText={false}
                    />
                  </Grid>
                  <Grid item xs={12} key="banks">
                    <SelectArrayInput
                      allowEmpty
                      label="Banks"
                      source="params.banks"
                      helperText={false}
                      choices={banks}
                      optionText="name"
                      optionValue="code"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Grid item xs={12}>
                  <RadioGroup row className={classes.mb2}>
                    <FormControlLabel
                      value="onetime"
                      control={<Radio />}
                      label="One-time"
                      checked={daysMode === 'onetime'}
                      onChange={() => {
                        setDaysMode('onetime');
                        formProps.form.change('params.week_days', []);
                        formProps.form.change('params.month_days', []);
                      }}
                    />
                    <FormControlLabel
                      value={DAYS_MODE_DOW}
                      control={<Radio />}
                      label="Days of week"
                      checked={daysMode === DAYS_MODE_DOW}
                      onChange={() => {
                        setDaysMode(DAYS_MODE_DOW);
                        formProps.form.change('one_time_allocations', null);
                        formProps.form.change('params.month_days', []);
                      }}
                    />
                    <FormControlLabel
                      value={DAYS_MODE_DOM}
                      control={<Radio />}
                      label="Days of month"
                      checked={daysMode === DAYS_MODE_DOM}
                      onChange={() => {
                        setDaysMode(DAYS_MODE_DOM);
                        formProps.form.change('one_time_allocations', null);
                        formProps.form.change('params.week_days', []);
                      }}
                    />
                  </RadioGroup>
                  {daysMode === 'onetime' && (
                    <Grid item xs={12} sm={6}>
                      <DatePickerInput
                        source="one_time_allocations"
                        disableToolbar
                        variant="inline"
                        dateFormat="yyyy-MM-dd"
                        margin="none"
                        label="One-time assignment date"
                        disablePast
                        required
                        validate={[required('Required'), dateValidation]}
                        defaultValue={
                          formProps.record.params.one_time_allocations
                            ? formProps.record.params.one_time_allocations[0]
                            : null
                        }
                      />
                    </Grid>
                  )}
                  {daysMode === DAYS_MODE_DOW && (
                    <CheckboxGroupInput
                      source="params.week_days"
                      validate={required('Required')}
                      label={false}
                      choices={weekDaysChoices}
                    />
                  )}
                  {daysMode === DAYS_MODE_DOM && (
                    <CheckboxGroupInput
                      source="params.month_days"
                      validate={required('Required')}
                      label={false}
                      choices={monthDaysChoices}
                    />
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <Grid item xs={12}>
                  <BooleanInput
                    label="Close the loan in case of successful processing (if there is no outstanding principal on the loan)"
                    source="params.close_after_pay_principal"
                    helperText={false}
                    onChange={checked => {
                      setIsCloseAfterPayPrincipalSelected(checked);
                      setAmountSelected('full');
                      formProps.form.change('params.add_percent', null);
                      formProps.form.change('params.add_amount', null);
                      checked
                        ? formProps.form.change('params.amount', 'principal')
                        : formProps.form.change('params.amount', null);
                    }}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} key="amount">
                    <SelectInput
                      label="Amount"
                      helperText={false}
                      source="params.amount"
                      choices={amountChoices}
                      fullWidth
                      onChange={e => {
                        setAmountSelected(e.target.value);
                        formProps.form.change('params.add_amount', null);
                        formProps.form.change('params.add_percent', null);
                      }}
                    />
                  </Grid>
                  {['principal_and_amount', 'pay_amount', 'pay_amount_lower_then_principal'].includes(
                    amountSelected,
                  ) && (
                    <Grid item xs={6} key="add_amount">
                      <NumberInput
                        label="Add amount"
                        source="params.add_amount"
                        min={1}
                        max={65535}
                        step={1}
                        validate={[required(), minValue(1), maxValue(65535)]}
                        fullWidth
                        helperText={false}
                      />
                    </Grid>
                  )}
                  {amountSelected === 'principal_and_percent' && (
                    <Grid item xs={6} key="add_percent">
                      <NumberInput
                        label="Add percent"
                        source="params.add_percent"
                        min={1}
                        max={65535}
                        step={1}
                        validate={[required(), minValue(1), maxValue(65535)]}
                        fullWidth
                        helperText={false}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      resource="templates"
                      source="template_id"
                      label="Template"
                      optionValueProp="id"
                      optionLabelProp="key"
                      filter={{ is_enabled: true, category: 'SMS' }}
                      onFieldChange={e => {
                        setIsTemplateSelected(Boolean(e.target.textContent));
                        !e.target.textContent && formProps.form.change('params.send_template', null);
                      }}
                    />
                  </Grid>
                  {isTemplateSelected && (
                    <Grid item xs={6}>
                      <SelectInput
                        label="Send template"
                        helperText={false}
                        source="params.send_template"
                        choices={[
                          { id: 'send_immediately', name: 'Immediately' },
                          { id: 'send_today_at_12', name: 'Today at 12' },
                          { id: 'send_today_at_18', name: 'Today at 18' },
                          { id: 'send_next_day_at_12', name: 'Next day at 12' },
                          { id: 'send_next_day_at_18', name: 'Next day at 18' },
                          { id: 'send_in_2_days_at_12', name: 'In 2 days at 12' },
                          { id: 'send_in_2_days_at_18', name: 'In 2 days at 18' },
                        ]}
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <SaveButton
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    template_id: PropTypes.number,
    params: PropTypes.shape({
      week_days: PropTypes.array,
      month_days: PropTypes.array,
      allocated_days: PropTypes.number,
      allocated_date: PropTypes.string,
      one_time_allocations: PropTypes.array,
      external_agencies: PropTypes.array,
      allocated_time: PropTypes.string,
      amount: PropTypes.string,
      close_after_pay_principal: PropTypes.bool,
    }),
    is_adhoc: PropTypes.bool,
  }),
};

const DebitCampaignEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default DebitCampaignEdit;
