import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { formatCurrency, formatDatetime } from '../../utils/formatter';

import ActivateExtensionDialog from './ActivateExtensionDialog';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
}));

const ExtensionTable = ({ loanId, refreshedAt, onTransition }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [extensions, setExtensions] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [isActivateExtensionDialogOpened, setIsActivateExtensionDialogOpened] = useState(false);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    dataProvider
      .getList('extensions', {
        filter: { 'loan.id': loanId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setExtensions(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(extensions)) return null;

  const openActivationDialog = id => {
    setRowId(id);
    setIsActivateExtensionDialogOpened(true);
  };

  const closeActivationDialog = () => {
    setRowId(undefined);
    setIsActivateExtensionDialogOpened(false);
  };

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Price when requested</TableCell>
            <TableCell>Tenor</TableCell>
            <TableCell>Requested by</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Activated at</TableCell>
            <TableCell>Canceled at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {extensions.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>{row.price && formatCurrency(row.price)}</TableCell>
              <TableCell>{row.tenor}</TableCell>
              <TableCell className={classes.breakableCell}>
                {row.created_by_whom} {row.created_by_username ? `/ ${row.created_by_username} ` : ''}
              </TableCell>
              <TableCell>
                {row.started_at && `Started at ${formatDatetime(row.started_at)}`}
                <br />
                {row.ended_at && `Ended at ${formatDatetime(row.ended_at)}`}
              </TableCell>
              <TableCell>
                {row.activated_at && (
                  <>
                    {formatDatetime(row.activated_at)}
                    <br />
                    {row.activated_at && `Activated by ${row.activated_by}`}
                  </>
                )}
              </TableCell>
              <TableCell className={classes.breakableCell}>
                {row.canceled_at && (
                  <>
                    {formatDatetime(row.canceled_at)}
                    <br />
                    {row.canceled_by && `Canceled by ${row.canceled_by}`}
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                <ButtonGroup
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={permissions.indexOf('CAN_EXTENSION_EDIT') === -1}>
                  {row.enabled_transitions.indexOf('reject') !== -1 && (
                    <Button onClick={() => onTransition(row.id, 'reject')}>Reject</Button>
                  )}
                  {row.enabled_transitions.indexOf('activate') !== -1 && (
                    <Button onClick={() => openActivationDialog(row.id)}>Activate</Button>
                  )}
                  {row.enabled_transitions.indexOf('cancel') !== -1 && (
                    <Button onClick={() => onTransition(row.id, 'cancel')}>Cancel</Button>
                  )}
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      {permissions.indexOf('CAN_EXTENSION_EDIT') !== -1 ? (
        <ActivateExtensionDialog
          isOpened={isActivateExtensionDialogOpened}
          onClose={closeActivationDialog}
          onSubmit={extension_date => {
            onTransition(rowId, 'activate', { extension_date });
            closeActivationDialog();
          }}
        />
      ) : null}
    </div>
  );
};

ExtensionTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
};

export default ExtensionTable;
