import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  NumberInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  useNotify,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import { marginZeroStyles } from '../../../constants';
import { formatDatetime } from '../../../utils/formatter';

import BulkActionButtons from './BulkActionButtons';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
  },
}));

const ListFilter = props => {
  const classes = useStyles();

  const triggerChoices = [
    { id: 'income', name: 'Income' },
    { id: 'login', name: 'Login' },
    { id: 'refresh_token', name: 'Refresh token' },
    { id: 'login_by_contract_number', name: 'Login by contract number' },
    { id: 'login_by_phone', name: 'Login by phone' },
    { id: 'login_by_short_link_personal_account', name: 'Login by short link personal account' },
    { id: 'login_by_short_link_extension', name: 'Login by short link extension' },
    { id: 'promise_payment_date', name: 'Promise date' },
    { id: 'repeat_communication_reminder_date', name: 'Communication reminder' },
    { id: 'related_project_loan_closed', name: 'Repaid loan in another company' },
  ];

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectInput label="Trigger" source="trigger" choices={triggerChoices} />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Read before" source="read_at|before" before />
      <DateFilterInput label="Read after" source="read_at|after" after />
      <NumberInput label="User Id" source="user->id" />
      <NumberInput label="Loan Id" source="loan->id" />
      <NumberInput label="Application Id" source="application->id" />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const readAllNotifications = () => {
    dataProvider
      .query('admin_notifications/read_all', { method: 'POST' })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
      <Button variant="text" color="primary" size="small" startIcon={<DoneIcon />} onClick={readAllNotifications}>
        Read all notifications
      </Button>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const getOperatorId = () => {
  const userJson = localStorage.getItem('admin_fields');
  if (!userJson) {
    return 'Unknown';
  }
  const userObject = JSON.parse(userJson);
  return userObject?.id ?? null;
};

const getTriggerText = trigger => {
  switch (trigger) {
    case 'income':
      return 'Payment';
    case 'login':
      return 'Login to personal area (Login)';
    case 'refresh_token':
      return 'Login to personal area (Refresh token)';
    case 'login_by_contract_number':
      return 'Payment page (Contract number)';
    case 'login_by_phone':
      return 'Payment page (Phone number)';
    case 'login_by_short_link_personal_account':
      return 'Login by short link (Personal area)';
    case 'login_by_short_link_extension':
      return 'Login by short link (Extension)';
    case 'promise_payment_date':
      return 'Promise date';
    case 'repeat_communication_reminder_date':
      return 'Communication reminder';
    case 'related_project_loan_closed':
      return 'Repaid loan in another company';
    default:
      return 'N/D';
  }
};

const getComment = row => {
  switch (row.trigger) {
    case 'income':
      return (
        <Typography variant="body2">
          {row.income_payment_type}
          {': '}
          <b>${row.income_amount}</b>
        </Typography>
      );
    case 'related_project_loan_closed':
      return (
        <Typography variant="body2">
          {`Name: ${row.fields?.name}`}
          <br />
          {`CURP: ${row.fields?.curp}`}
          <br />
          {`Closed at: ${formatDatetime(row.fields?.closed_at)}`}
          <br />
          {`Last income amount: ${row.fields?.last_income_amount}`}
        </Typography>
      );
    default:
      return (
        <Typography variant="body2">
          {`IP: ${row.ip}`}
          <br />
          {`Device: ${row.device}`}
          <br />
          {`Browser: ${row.browser}`}
          <br />
          {`OS: ${row.os}`}
        </Typography>
      );
  }
};

const AdminNotificationsList = props => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const readNotification = id => {
    dataProvider
      .query(`admin_notifications/${id}/read`, { method: 'POST' })
      .then(() => document.dispatchEvent(new Event('check-admin-notifications')))
      .catch(error => notify(`Notification #${id}: ${error.message}`, 'error'));
  };

  const unreadNotification = id => {
    dataProvider
      .query(`admin_notifications/${id}/unread`, { method: 'POST' })
      .then(() => document.dispatchEvent(new Event('check-admin-notifications')))
      .catch(error => notify(`Notification #${id}: ${error.message}`, 'error'));
  };

  const getRowStyle = record => {
    if (record.read_at === null) {
      return { backgroundColor: blue[100] };
    }
  };

  const onRowClick = (id, loanId) => {
    readNotification(id);
    document.dispatchEvent(new Event('check-admin-notifications'));
    return `/loans/${loanId}/show`;
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<BulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filter={{ 'admin.id': getOperatorId() }}
      filters={<ListFilter />}
      actions={<ListActions className={classes.toolbar} />}
      {...props}>
      <Datagrid rowStyle={getRowStyle} rowClick={(id, _basePath, record) => onRowClick(id, record.loan_id)}>
        <TextField source="id" />
        <FunctionField
          render={record =>
            record.read_at === null ? (
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  readNotification(record.id);
                }}>
                <VisibilityIcon color="secondary" fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  unreadNotification(record.id);
                }}>
                <VisibilityOffIcon color="secondary" fontSize="small" />
              </IconButton>
            )
          }
        />
        <TextField label="Admin Id" source="admin_id" sortable={false} />
        <FunctionField
          render={({ user_id, application_id, loan_id }) => (
            <Typography variant="body2">
              <b>User ID: </b>
              {user_id}
              <br />
              <b>Application ID: </b>
              {application_id}
              <br />
              <b>Loan ID: </b>
              {loan_id}
            </Typography>
          )}
        />
        <FunctionField
          label="Collector"
          render={({
            loan_collector_agency_id,
            loan_collector_agency_name,
            loan_collector_id,
            loan_collector_username,
          }) => {
            if (loan_collector_agency_id) {
              return `Agency #${loan_collector_agency_id} ${loan_collector_agency_name}`;
            } else if (loan_collector_id) {
              return `Collector #${loan_collector_id} ${loan_collector_username}`;
            } else {
              return null;
            }
          }}
        />
        <FunctionField
          label="Loan group"
          render={({ loan_collection_group_code }) =>
            loan_collection_group_code && <Chip label={loan_collection_group_code} size="small" />
          }
        />
        <TextField label="DPD" source="loan_dpd" sortable={false} />
        <FunctionField label="Trigger" source="trigger" render={record => getTriggerText(record.trigger)} />
        <FunctionField label="Comment" render={record => getComment(record)} sortable={false} />
        <FunctionField
          label="Created/Read"
          render={({ created_at, read_at }) => (
            <Typography variant="body2">
              <b>Created at:</b>
              <br />
              {formatDatetime(created_at)}
              <br />
              <b>Read at:</b>
              <br />
              {formatDatetime(read_at)}
            </Typography>
          )}
        />
        <FunctionField
          render={record => (
            <Link href={`#/loans/${record.loan_id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  readNotification(record.id);
                }}>
                <OpenInNewIcon color="secondary" fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default AdminNotificationsList;
