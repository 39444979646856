import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  Toolbar,
  SelectInput,
  SelectArrayInput,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useHandbook } from '../../../hooks/useHandbook';
import { TRIGGERS_CHOICES } from '../../../utils/dictionary';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const { data: collectionGroupsResponse } = useHandbook('collection_groups', { items_per_page: 30 });

  const collectionGroups = collectionGroupsResponse.map(group => ({
    id: group.code,
    name: group.code,
  }));

  return (
    <FormWithRedirect
      save={({ trigger, groups }, ...rest) => save(...[{ trigger, groups, _params: { method: 'PATCH' } }, ...rest])}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit collection alert
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <SelectInput
                        source="trigger"
                        label="Event name"
                        resettable
                        helperText={false}
                        choices={TRIGGERS_CHOICES}
                        fullWidth
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid item xs={12} key="banks">
                      <SelectArrayInput
                        allowEmpty
                        label="Collection groups"
                        source="groups"
                        helperText={false}
                        choices={collectionGroups}
                        optionText="name"
                        optionValue="id"
                        fullWidth
                        validate={[required()]}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={false}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const CollectionAlertsSettingsEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default CollectionAlertsSettingsEdit;
