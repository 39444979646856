import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Autocomplete from '../autocomplete/Autocomplete';
import { SMS_TRANSMITTERS as smsTransmitters } from '../../utils/dictionary';
import { isProduction } from '../../utils/env';
import { useEmailSources } from '../../hooks/useEmailSources';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const AddNotificationDialog = ({ onClose, onSubmit, defaultPhoneNumber, defaultEmailAddress }) => {
  const [transmitterId, setTransmitterId] = useState(null);
  const [isTransmitterEmail, setIsTransmitterEmail] = useState(false);
  const [templateFilter, setTemplateFilter] = useState({ is_enabled: true });
  const [switchValue, setSwitchValue] = useState('template');
  const [loading, setLoading] = useState(false);
  const [defaultSmsTransmitter, setDefaultSmsTransmitter] = useState(null);
  const { isEmailSelected, handleFormChange } = useEmailSources();

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const transmitters = isProduction
    ? [
        { id: 'sms', name: 'SMS' },
        { id: 'mailer', name: 'E-mail' },
      ]
    : [
        { id: 'fake', name: 'Fake' },
        { id: 'sms', name: 'SMS' },
        { id: 'mailer', name: 'E-mail' },
      ];

  useEffect(() => {
    permissions.length > 0 &&
      permissions.includes('CAN_BE_COLLECTION_SPECIALIST') &&
      setTemplateFilter(t => ({ ...t, sending_type: 'manual' }));
  }, [permissions]);

  useEffect(() => {
    setIsTransmitterEmail(typeof transmitterId === 'string' && /mail/.test(transmitterId));
  }, [transmitterId]);

  useEffect(() => {
    setTransmitterId(null);
    setIsTransmitterEmail(false);
    setSwitchValue('template');
    dataProvider
      .query('global_settings/by-name/default_sms_transmitter', {})
      .then(({ data }) => setDefaultSmsTransmitter(data.value))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    return () => setLoading(false);
  }, [dataProvider, notify]);

  const handleTransmitterChange = (e, form) => {
    const value = e.target.value;
    if (value === 'sms') {
      const transmitterName = defaultSmsTransmitter ? smsTransmitters[defaultSmsTransmitter] : smsTransmitters['1'];
      setTemplateFilter({ ...templateFilter, category: 'SMS' });
      setTransmitterId(transmitterName);
    } else if (value === 'mailer') {
      setTemplateFilter({ ...templateFilter, category: 'Email' });
      setTransmitterId(value);
    } else if (value === 'fake') {
      setTemplateFilter({ ...templateFilter, category: 'SMS' });
      setTransmitterId(value);
    } else {
      setTemplateFilter({ ...templateFilter });
      setTransmitterId(value);
    }
    form.change('template', null);
    form.change('subject', null);
    form.change('message', null);
    form.change('destination', value === 'mailer' ? defaultEmailAddress : defaultPhoneNumber);
    setSwitchValue('template');
    handleFormChange(e, form);
  };

  const handleSwitchChange = (event, form) => {
    setSwitchValue(event.target.value);
    form.change('subject', null);
    form.change('template', null);
    form.change('message', null);
  };

  const handleSubmit = values => {
    setLoading(true);
    const { destination, template_id, subject, message, email_source } = values;
    onSubmit(transmitterId, destination, template_id, message, subject, email_source);
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth size="lg">
      <DialogTitle id="form-dialog-title">Add user notification</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ destination: defaultPhoneNumber }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="transmitter-select-label">Transmitter</InputLabel>
                    <Field name="transmitter_id">
                      {({ input }) => (
                        <Select
                          labelId="transmitter-select-label"
                          {...input}
                          onChange={e => {
                            input.onChange(e);
                            handleTransmitterChange(e, form);
                          }}>
                          {transmitters.map((option, idx) => (
                            <MenuItem key={idx} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                {isEmailSelected && (
                  <Grid item xs={12} sm={6}>
                    <Field name="email_source">
                      {({ input }) => (
                        <Autocomplete
                          resource="email_outbox_addresses"
                          source="email_source"
                          optionValueProp="email"
                          optionLabelProp="email"
                          variant="standard"
                          margin="none"
                          size="medium"
                          {...input}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Field name="destination">
                    {({ input }) => <TextField fullWidth label="Destination" {...input} />}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="template message switch"
                      name="switch"
                      value={switchValue}
                      onChange={e => {
                        handleSwitchChange(e, form);
                      }}>
                      <FormControlLabel value="template" control={<Radio size="small" />} label="Use template" />
                      <FormControlLabel value="message" control={<Radio size="small" />} label="Use message" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {switchValue === 'template' && (
                  <Grid item xs={12}>
                    <Field name="template">
                      {({ input }) => (
                        <Autocomplete
                          resource="templates"
                          source="template_id"
                          optionValueProp="id"
                          optionLabelProp="key"
                          filter={templateFilter}
                          variant="standard"
                          margin="none"
                          size="medium"
                          {...input}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {isTransmitterEmail && switchValue === 'message' && (
                  <Grid item xs={12}>
                    <Field name="subject">
                      {({ input }) => <TextField margin="none" fullWidth label="Subject" {...input} />}
                    </Field>
                  </Grid>
                )}
                {switchValue === 'message' && (
                  <Grid item xs={12}>
                    <Field name="message">
                      {({ input }) => (
                        <TextField margin="none" multiline minRows="3" fullWidth label="Message" {...input} />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
              <DialogActions className={classes.mt4}>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : null}
                  color="primary"
                  disabled={
                    !values.transmitter_id ||
                    !values.destination ||
                    !(switchValue === 'template'
                      ? values.template_id
                      : isTransmitterEmail
                        ? values.message && values.subject
                        : values.message)
                  }>
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddNotificationDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultPhoneNumber: PropTypes.string,
  defaultEmailAddress: PropTypes.string,
};

AddNotificationDialog.defaultProps = {
  defaultPhoneNumber: '',
  defaultEmailAddress: '',
};

export default AddNotificationDialog;
