import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  Filter,
  NumberInput,
  useDataProvider,
  useNotify,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import LinearProgressField from '../../field/LinearProgressField';
import { formatDatetime } from '../../../utils/formatter';
import openIntoNewTab from '../../../utils/openIntoNewTab';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const DownloadButtonField = ({ record = {} }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const downloadHandler = reportId => {
    setIsLoading(true);
    dataProvider
      .downloadFile(`reports/${reportId}/download`)
      .then(({ data: objUrl, file_name }) => {
        openIntoNewTab(objUrl, file_name);
      })
      .catch(error => {
        notify(`Error: ${error.message || 'Can not download report file'}`, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <IconButton
      color="primary"
      disabled={record.state !== 'processed' || isLoading}
      onClick={() => downloadHandler(record.id)}>
      <CloudDownloadIcon />
    </IconButton>
  );
};

DownloadButtonField.propTypes = {
  record: PropTypes.shape({
    state: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}>
    <Datagrid>
      <TextField source="id" />
      <ChipField size="small" source="state" />
      <ChipField size="small" source="builder_id" />
      <LinearProgressField source="progress" />
      <FunctionField
        render={({ params }) => (params.start_date ? formatDatetime(params.start_date) : '---')}
        label="Start date"
      />
      <FunctionField
        render={({ params }) => (params.end_date ? formatDatetime(params.end_date) : '---')}
        label="End date"
      />
      <FunctionField render={({ created_at }) => (created_at ? formatDatetime(created_at) : null)} label="Created at" />
      <FunctionField
        label="Created by"
        render={({ created_by_id, created_by_name = '' }) =>
          created_by_id ? created_by_name + '#' + created_by_id : ''
        }
      />
      <FunctionField render={({ started_at }) => (started_at ? formatDatetime(started_at) : null)} label="Started at" />
      <FunctionField
        render={({ finished_at }) => (finished_at ? formatDatetime(finished_at) : null)}
        label="Finished at"
      />
      <DownloadButtonField />
    </Datagrid>
  </List>
);
