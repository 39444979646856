import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  Loading,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import Call from '../../call/Call';
import { timezone } from '../../../utils/adapter';
import { LOAN_LIST_ROWS } from '../loan/constants';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import { TitleCloseDialog } from '../../dialog/Dialog';
import { calculateAge, formatDatetime, formatPhoneNumber } from '../../../utils/formatter';
import Pagination from '../../Pagination';
import QuickFilterInput from '../../filter/QuickFilterInput';
import DateFilterInput from '../../filter/DateFilterInput';
import {
  CALL_CENTER_USER_STATUS_CLOSED_LOANS,
  CALL_CENTER_USER_STATUS_UNCOMPLETED_REG,
  CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS,
  formatChoices,
} from '../../../utils/dictionary';
import { ListActions } from '../user/UserList';
import { useHandbook } from '../../../hooks/useHandbook';
import { marginZeroStyles } from '../../../constants';

import { assignEssence, changeStatus } from './actions';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const RedMark = ({ phone, title }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box>{phone}</Box>
      <Box>
        <Tooltip title={title}>
          <Box pl={1}>
            <CloseIcon color="error" />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

RedMark.propTypes = {
  phone: PropTypes.string,
  title: PropTypes.string,
};

export const ListFilter = props => {
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="Registration step" source="registration_step" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <TextInput label="First name" source="profile->first_name" />
      <TextInput label="Father surname" source="profile->last_name" />
      <TextInput label="Last name" source="profile->last_name" />
      <TextInput label="Document number" source="userDocuments->number" />
      <NumberInput label="Phone number" source="profile->phone_number" />
      <TextInput label="Email" source="profile->email" />
      <TextInput label="Account number" source="bankAccount->account_number" />
      <SelectInput label="Empl. type" source="employment->type->code" choices={employmentTypeChoices} />
      <QuickFilterInput source="sleeping_clients" label="Sleeping clients" defaultValue={true} />
      <SelectInput
        label="Last call result"
        source="phone_call_last_result"
        choices={formatChoices(CALL_CENTER_USER_STATUS_UNCOMPLETED_REG)}
      />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{
          resource: 'affiliates',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <AsyncSelectInput
        label="Operator"
        source="phone_call_operator"
        query={{
          resource: 'admins?role_permission=CAN_BE_PHONE_CALL_OPERATOR',
          payload: { pagination: {}, sort: {}, filter: {} },
        }}
        mapper={({ id, username }) => ({ value: id, name: username })}
        type="getList"
        additionalData={[{ id: 'null', username: 'Not assign' }]}
      />
      <NumberInput label="Age less" source="age|lt" />
      <NumberInput label="Age greater" source="age|gt" />
      <DateFilterInput label="Assigned before" source="phone_call_assigned_at|before" before />
      <DateFilterInput label="Assigned after" source="phone_call_assigned_at|after" after />
    </Filter>
  );
};

export const ListFilterUnsigned = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <TextInput label="User ID" source="user->id" />
      <NumberInput label="Request Id" source="id" />
      <DateFilterInput label="Assign before" source="unsigned_request_phone_call_assigned_at|before" before />
      <DateFilterInput label="Assign after" source="unsigned_request_phone_call_assigned_at|after" after />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <SelectInput
        label="Last call result"
        source="phone_call_last_result"
        choices={formatChoices(CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS)}
      />
    </Filter>
  );
};

const DropdownDialog = ({ isOpened, onClose, onSubmit, selectOptions, title }) => {
  const [userStatus, setUserStatus] = useState();
  return (
    <Dialog open={isOpened || false} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Status</DialogContentText>
        <FormControl fullWidth>
          <Select
            value={userStatus || ''}
            onChange={e => {
              setUserStatus(() => e.target.value);
            }}>
            {selectOptions.map(i => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onSubmit(userStatus)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DropdownDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectOptions: PropTypes.array,
  title: PropTypes.string,
};

const LogDialog = ({ open, close, children, title }) => (
  <TitleCloseDialog open={open || false} close={close} title={title} maxWidth={'md'}>
    {children}
  </TitleCloseDialog>
);

LogDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
};

export const CallCenterLogTable = ({ filter, refreshedAt, isDialog }) => {
  const dataProvider = useDataProvider();
  const [logData, setLogData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const notify = useNotify();
  useEffect(() => {
    filter &&
      dataProvider
        .getList('phone_calls', {
          filter,
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data, total }) => {
          setLogData(data);
          setTotal(total);
        })
        .catch(error => notify(`Error: ${error.message || 'log not found'}`, 'error'))
        .finally(() => setLoading(false));
    return () => {
      setLogData([]);
    };
  }, [dataProvider, notify, perPage, refreshedAt, filter, page]);
  if (loading && !isDialog) return <Loading />;
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>User id</TableCell>
            <TableCell>Admin id</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Updated at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logData.map((item, key) => {
            return (
              <TableRow key={key + '_log'}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.user_id}</TableCell>
                <TableCell>{item.admin_id}</TableCell>
                <TableCell>
                  <Chip label={item.result} size="small" />
                </TableCell>
                <TableCell>{item.admin_username}</TableCell>
                <TableCell>{formatDatetime(item.created_at)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </>
  );
};

CallCenterLogTable.propTypes = {
  filter: PropTypes.object,
  refreshedAt: PropTypes.number,
  isDialog: PropTypes.bool,
};

export const CallCenterList = ({ permissions, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [userIdLog, setUserIdLog] = useState();
  const [userIdStatus, setUserIdStatus] = useState();

  const showFullPhone = !permissions?.includes('CAN_SEE_FULL_PHONE');
  const canEdit = permissions?.includes('CAN_PHONE_CALL_EDIT');

  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title="User status log">
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'user.id': userIdLog, context: 'user_uncompleted_registrations' }}
          isDialog
        />
      </LogDialog>
      <DropdownDialog
        title="Change user status"
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(userStatus, dataProvider, notify, setOpenDropdownDialog, refresh, 'phone_calls', {
            user_id: userIdStatus,
            result: userStatus,
            context: 'user_uncompleted_registrations',
          })
        }
        selectOptions={CALL_CENTER_USER_STATUS_UNCOMPLETED_REG}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<ListActions />}
        permissions={permissions}
        {...props}>
        <Datagrid rowClick="edit">
          <TextField key="id" source="id" />
          <FunctionField
            key="phone_number"
            source="phone_number"
            render={({ phone_number, birthday, employment_type, residence_district }) => {
              const age = calculateAge(
                new Date(birthday).getMonth(),
                new Date(birthday).getDate(),
                new Date(birthday).getFullYear(),
                timezone.shift(new Date()),
              );
              const phone = phone_number && formatPhoneNumber(phone_number, showFullPhone);
              if (age < 20) {
                return <RedMark phone={phone} title={'Age < 20'} />;
              } else if (employment_type === 'Temporarily Unemployed') {
                return <RedMark phone={phone} title={'Employment type is a ' + employment_type} />;
              } else if (
                residence_district === 'Monaragala' ||
                residence_district === 'Batticaloa' ||
                residence_district === 'Mannar' ||
                residence_district === 'Mullaitivu'
              ) {
                return <RedMark phone={phone} title={'Residence district is a ' + residence_district} />;
              } else {
                return phone;
              }
            }}
          />
          <TextField key="first_name" source="first_name" />
          <TextField key="last_name" source="last_name" />
          <TextField key="gender" source="gender" />
          <TextField key="employment_type" source="employment_type" />
          <FunctionField
            label="Registration date"
            key="created_at"
            render={({ created_at }) => formatDatetime(created_at)}
          />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.user_uncompleted_registrations?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setUserIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={!canEdit}>
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setUserIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}>
                        Log
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(dataProvider, notify, refresh, 'phone_call_user_assigns', {
                        user_id: id,
                        context: 'user_uncompleted_registrations',
                      });
                    }}
                    disabled={!canEdit}>
                    Assign
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            label="Operator"
            render={({ last_phone_calls_by_context }) => (
              <Chip
                label={last_phone_calls_by_context?.user_uncompleted_registrations?.operator || 'none'}
                size="small"
              />
            )}
          />
          <FunctionField
            label="Last call result"
            render={({ last_phone_calls_by_context }) => (
              <Chip
                label={last_phone_calls_by_context?.user_uncompleted_registrations?.result || 'none'}
                size="small"
              />
            )}
          />
          <FunctionField
            label="Last activity"
            render={({ last_phone_calls_by_context }) =>
              formatDatetime(last_phone_calls_by_context?.user_uncompleted_registrations?.called_at)
            }
          />
          <FunctionField
            label="Step"
            source="registration_step"
            render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
          />
          <FunctionField
            label="Affiliate"
            source="affiliate"
            render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
          />
          <FunctionField
            render={record => (
              <Link
                href={`/admin#/uncompleted_registration/${record.id}`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <IconButton onClick={e => e.stopPropagation()}>
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </Link>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

CallCenterList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export const CallCenterListFiltersClosed = ({ statefilterchoices, ...props }) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <NumberInput label="Phone number" source="user->profile->phone_number" />
      {statefilterchoices.length ? <SelectInput label="Status" source="state" choices={statefilterchoices} /> : null}
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Closed before" source="closed_at|before" before />
      <DateFilterInput label="Closed after" source="closed_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <SelectInput
        label="Loan type"
        source="is_repeat"
        choices={[
          { id: 'false', name: 'New' },
          { id: 'true', name: 'Repeated' },
        ]}
      />
      <QuickFilterInput source="is_direct_debit_registered" label="Direct debit registered" defaultValue={true} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
    </Filter>
  );
};

CallCenterListFiltersClosed.propTypes = {
  statefilterchoices: PropTypes.array,
};

export const CallCenterListClosedLoans = ({
  statefilterchoices = [],
  listfilters: ListFilters,
  permissions,
  ...props
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [loanIdLog, setLoanIdLog] = useState();
  const [loanIdStatus, setLoanIdStatus] = useState();

  const canEdit = permissions?.includes('CAN_PHONE_CALL_EDIT');

  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title="Loan status log">
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'loan.id': loanIdLog, context: 'loan_closed_loans' }}
          isDialog
        />
      </LogDialog>
      <DropdownDialog
        title="Change loan status"
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(userStatus, dataProvider, notify, setOpenDropdownDialog, refresh, 'phone_calls', {
            loan_id: loanIdStatus,
            result: userStatus,
            context: 'loan_closed_loans',
          })
        }
        selectOptions={CALL_CENTER_USER_STATUS_CLOSED_LOANS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilters statefilterchoices={statefilterchoices} />}
        actions={<ListActions />}
        permissions={permissions}
        {...props}>
        <Datagrid rowClick="show">
          {LOAN_LIST_ROWS.slice(0, 9).map(i => i)}
          <FunctionField label="Closing date" render={({ closed_at }) => formatDatetime(closed_at)} />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.loan_closed_loans?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={!canEdit}>
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}>
                        log
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(dataProvider, notify, refresh, 'phone_call_loan_assigns', {
                        loan_id: id,
                        context: 'loan_closed_loans',
                      });
                    }}
                    disabled={!canEdit}>
                    Assign
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            label="Operator"
            render={({ last_phone_calls_by_context }) => (
              <Chip label={last_phone_calls_by_context?.loan_closed_loans?.operator || 'none'} size="small" />
            )}
          />
          <FunctionField
            label="Last call result"
            render={({ last_phone_calls_by_context }) => (
              <Chip label={last_phone_calls_by_context?.loan_closed_loans?.result || 'none'} size="small" />
            )}
          />
          <FunctionField
            label="Last activity"
            render={({ last_phone_calls_by_context }) =>
              formatDatetime(last_phone_calls_by_context?.loan_closed_loans?.called_at)
            }
          />
          {LOAN_LIST_ROWS.slice(9).map(i => i)}
        </Datagrid>
      </List>
    </>
  );
};

CallCenterListClosedLoans.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export const CallCenterListUnsignedRequests = ({
  statefilterchoices = [],
  listfilters: ListFilters,
  permissions,
  ...props
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [loanIdLog, setLoanIdLog] = useState();
  const [loanIdStatus, setLoanIdStatus] = useState();

  const canSeePhone = !permissions.includes('CAN_SEE_FULL_PHONE');
  const canEdit = permissions?.includes('CAN_PHONE_CALL_EDIT');

  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title="Request status log">
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'application.id': loanIdLog, context: 'application_unsigned_applications' }}
          isDialog
        />
      </LogDialog>
      <DropdownDialog
        title="Change request status"
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(userStatus, dataProvider, notify, setOpenDropdownDialog, refresh, 'phone_calls', {
            application_id: loanIdStatus,
            result: userStatus,
            context: 'application_unsigned_applications',
          })
        }
        selectOptions={CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        actions={<ListActions />}
        filters={<ListFilters statefilterchoices={statefilterchoices} />}
        permissions={permissions}
        {...props}>
        <Datagrid
          rowClick="show"
          rowStyle={i => {
            let background = theme.palette.background.paper;
            const smsTime = new Date(i.resolved_at);
            const nowTime = new Date();
            const operator = i.last_phone_calls_by_context?.application_unsigned_applications?.operator;
            const result = i.last_phone_calls_by_context?.application_unsigned_applications?.result;
            const min = Math.floor((nowTime - smsTime) / 60000);
            if (min > 15 && operator === null) {
              background = red[300];
            }
            if (min > 60 && (result === 'Is thinking' || result === 'Not answered' || result === 'Going to sign')) {
              background = red[300];
            }
            if (result === 'Is not interested') {
              background = orange[300];
            }
            if (min < 60 && result === 'Is thinking') {
              background = yellow[300];
            }
            if (min < 60 && result === 'Going to sign') {
              background = green[300];
            }
            return {
              backgroundColor: background,
            };
          }}>
          <FunctionField
            label="Request id / Created at"
            render={({ id, created_at }) => `${id} / ${formatDatetime(created_at)}`}
          />
          <FunctionField
            label="Name"
            render={({ user_id, user_first_name = '', user_last_name = '' }) => {
              return (
                <Link
                  onClick={e => e.stopPropagation()}
                  href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>
              );
            }}
          />
          ,
          <FunctionField
            label="Phone number"
            render={({ user_phone_number, user_id }) => (
              <Call userId={user_id} userPhone={user_phone_number}>
                {formatPhoneNumber(user_phone_number, canSeePhone)}{' '}
              </Call>
            )}
          />
          <TextField source="user_id" label="User id" />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.application_unsigned_applications?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={!canEdit}>
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}>
                        log
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(dataProvider, notify, refresh, 'phone_call_application_assigns', {
                        application_id: id,
                        context: 'application_unsigned_applications',
                      });
                    }}
                    disabled={!canEdit}>
                    Assign
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            label="Operator / assigning time"
            render={({ last_phone_calls_by_context }) => {
              return (
                (last_phone_calls_by_context?.application_unsigned_applications?.operator || 'not selected') +
                ' / ' +
                formatDatetime(last_phone_calls_by_context?.application_unsigned_applications?.called_at)
              );
            }}
          />
          <FunctionField
            label="Min PALC"
            render={({ last_phone_calls_by_context }) => {
              const calledAt = last_phone_calls_by_context?.application_unsigned_applications?.called_at;
              const changeTime = new Date(calledAt);
              const nowTime = new Date();
              if (calledAt) {
                return Math.floor((nowTime - changeTime) / 60000) + ' min';
              } else {
                return 0;
              }
            }}
          />
          <FunctionField
            label="Min paseed"
            render={({ resolved_at }) => {
              const smsTime = new Date(resolved_at);
              const nowTime = new Date();
              const min = Math.floor((nowTime - smsTime) / 60000);
              return min + ' min';
            }}
          />
          <FunctionField
            label="Last call result"
            render={({ last_phone_calls_by_context }) =>
              last_phone_calls_by_context?.application_unsigned_applications?.result
            }
          />
          <FunctionField
            label="Loan sum requested / approved"
            render={({ requested_principal, max_approved_principal }) =>
              `${requested_principal} / ${max_approved_principal}`
            }
          />
          <FunctionField
            label="Loan term requested / approved"
            render={({ requested_tenor, max_approved_tenor }) => `${requested_tenor} / ${max_approved_tenor}`}
          />
          <FunctionField
            render={record => (
              <Link
                href={`admin#/unsigned_requests/${record.id}/show`}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <IconButton onClick={e => e.stopPropagation()}>
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </Link>
            )}
            key="button_tab"
          />
        </Datagrid>
      </List>
    </>
  );
};

CallCenterListUnsignedRequests.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};
