import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Show, useRedirect, useRefresh, useDataProvider, useNotify, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ListIcon from '@material-ui/icons/List';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency, formatDatetime } from '../../../utils/formatter';
import PreviewDialog from './PreviewDialog';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  my2: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  mt2: {
    marginTop: theme.spacing(4),
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const Layout = ({ record }) => {
  const [refreshedAt, setRefreshedAt] = useState();
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [disableSwitchState, setDisableSwitchState] = useState(false);
  const [enabledCampaignState, setEnabledCampaignState] = useState(false);

  const classes = useStyles();
  const redirect = useRedirect();
  const onRefresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const refresh = () => {
    setRefreshedAt(Date.now());
    onRefresh();
  };

  useEffect(() => {
    if (record.campaign_type === 'onetime') {
      // eslint-disable-next-line no-unneeded-ternary
      setDisableSwitchState(record.is_enabled ? false : true);
    }
  }, [record, refreshedAt]);

  useEffect(() => {
    setEnabledCampaignState(record.is_enabled);
  }, [record]);

  const rowData = (name, value) => ({ name, value });

  const getDiscountDetails = record => {
    if (record.discount_type === 'amount') {
      return (
        <Typography variant="body2">
          <b>Discount MXN: </b>
          {formatCurrency(record.discount_total_amount)}
        </Typography>
      );
    } else if (record.discount_type === 'total_percent') {
      return (
        <Typography variant="body2">
          <b>Discount %: </b>
          {record.discount_total_percent}
        </Typography>
      );
    } else if (record.discount_type === 'compound_percent') {
      return (
        <>
          {record.discount_category !== 'loan_extension' && (
            <Typography variant="body2">
              <b>Discount for Principal %: </b>
              {record.discount_principal_percent}
            </Typography>
          )}
          <Typography variant="body2">
            <b>Discount for Interest %: </b>
            {record.discount_interest_percent}
          </Typography>
          <Typography variant="body2">
            <b>Discount for Past due interest %: </b>
            {record.discount_past_due_interest_percent}
          </Typography>
          <Typography variant="body2">
            <b>Discount for commission %: </b>
            {record.discount_commission_percent}
          </Typography>
        </>
      );
    } else {
      return null;
    }
  };

  const campaignRows = [
    rowData('ID', record.id),
    rowData('Name', record.name),
    rowData('Type of campaign', <Chip label={record.campaign_type} />),
    rowData('Category of campaign', record.discount_category.split('_').join(' ')),
    rowData('Type of discount', record.discount_type.split('_').join(' ')),
    rowData('Discount', getDiscountDetails(record)),
    record.discount_category === 'loan_extension' ? null : rowData('Number of payments', record.max_payments),
    rowData('Valid from', record.valid_from && formatDatetime(record.valid_from)),
    rowData('Valid to', record.valid_to && formatDatetime(record.valid_to)),
    rowData('Offer valid for (in days)', record.valid_days_count),
    rowData('Active', <Chip label={record.is_enabled ? 'Yes' : 'No'} />),
    rowData('Audience type', record.audience_type),
    rowData('Send notification', <Chip label={record.send_notification ? 'Yes' : 'No'} />),
    rowData('Display in private cabinet', <Chip label={record.show_to_client ? 'Yes' : 'No'} />),
    rowData('Created at', record.created_at && formatDatetime(record.created_at)),
    rowData('Created by', record.created_admin),
    rowData('Updated at', record.updated_at && formatDatetime(record.updated_at)),
    rowData('Updated by', record.updated_admin),
  ];

  const handleEnableCampaign = event => {
    setEnabledCampaignState(event.target.checked);
    dataProvider
      .query(`discount_campaigns/${record.id}`, {
        method: 'PATCH',
        body: JSON.stringify({ is_enabled: event.target.checked }),
      })
      .then(() => {
        notify(event.target.checked ? 'Campaign succesfully activated' : 'Campaign succesfully deactivated', 'success');
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => refresh());
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.bold}>
            Campaign
          </Typography>
          <Table size="small">
            <TableBody>
              {campaignRows.map(row => {
                if (!row) {
                  return null;
                }
                return (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {record.campaign_type === 'onetime' && (
            <Box className={classes.my2} display="flex" justifyContent="space-between">
              <Typography variant="subtitle1" className={classes.bold}>
                Participants:
              </Typography>
              <Typography variant="body2">{record.campaign_active_offers_count}</Typography>
            </Box>
          )}
          <Box className={classes.my2} display="flex" justifyContent="space-between">
            {record.campaign_type === 'onetime' && (
              <>
                <Button
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  startIcon={<ListIcon />}
                  disabled={!record.campaign_offers_preview || record.campaign_offers_preview.length === 0}
                  onClick={() => {
                    setIsPreviewDialogOpen(true);
                  }}>
                  Preview
                </Button>
                <PreviewDialog
                  previewList={record.campaign_offers_preview}
                  isOpened={isPreviewDialogOpen}
                  onClose={() => setIsPreviewDialogOpen(false)}
                />
              </>
            )}
            <FormControlLabel
              control={<Switch checked={enabledCampaignState} onChange={handleEnableCampaign} color="primary" />}
              label="Enabled"
              disabled={disableSwitchState}
            />
          </Box>
        </Paper>
      </Grid>
      {(record.audience_type === 'audience' || record.audience_type === 'file') &&
        record.campaign_type === 'onetime' &&
        (record.excluded_loan_ids ? (
          record.excluded_loan_ids.length === 0 ? (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1" className={classes.bold}>
                  List of loans that were excluded from discount campaign during campaign creation:{' '}
                  <Typography component="span" variant="body2">
                    No loans excluded
                  </Typography>
                </Typography>
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1" className={classes.bold}>
                  List of loans that were excluded from discount campaign during campaign creation:{' '}
                  <Typography component="span" variant="body2">
                    {record.excluded_loan_ids.join(', ')}
                  </Typography>
                </Typography>
              </Paper>
            </Grid>
          )
        ) : null)}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-start" gridGap={10} className={classes.ml2}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              redirect('/discount_campaigns');
            }}>
            Return to list
          </Button>
          {record.campaign_type !== 'onetime' && permissions.includes('CAN_DISCOUNT_CAMPAIGN_EDIT') && (
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => {
                redirect(`/discount_campaigns/${record.id}`);
              }}>
              Edit
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

Layout.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    campaign_type: PropTypes.string,
    discount_type: PropTypes.string,
    max_payments: PropTypes.number,
    valid_from: PropTypes.string,
    valid_to: PropTypes.string,
    is_enabled: PropTypes.bool,
    send_notification: PropTypes.bool,
    show_to_client: PropTypes.bool,
    created_at: PropTypes.string,
    created_admin: PropTypes.string,
    updated_at: PropTypes.string,
    updated_admin: PropTypes.string,
    campaign_active_offers_count: PropTypes.number,
    audience_type: PropTypes.string,
    discount_total_amount: PropTypes.number,
    discount_total_percent: PropTypes.number,
    discount_principal_percent: PropTypes.number,
    discount_interest_percent: PropTypes.number,
    discount_past_due_interest_percent: PropTypes.number,
    discount_commission_percent: PropTypes.number,
    campaign_offers_preview: PropTypes.array,
    excluded_loan_ids: PropTypes.array,
    discount_category: PropTypes.string,
    valid_days_count: PropTypes.number,
  }),
};

const DiscountCampaignShow = props => (
  <Show component="div" actions={false} {...props}>
    <Layout />
  </Show>
);

export default DiscountCampaignShow;
