export const getCellColorByPercentage = (percentageStr, color) => {
  const percentage = parseInt(percentageStr, 10);

  switch (true) {
    case percentage >= 0 && percentage <= 39:
      return color.red;
    case percentage >= 40 && percentage <= 69:
      return color.orange;
    case percentage >= 70 && percentage <= 100:
      return color.green;
    default:
      return null;
  }
};
