import React, { useEffect, useState } from 'react';
import { Link, useDataProvider, useNotify, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Autocomplete from '../../autocomplete/Autocomplete';
import { IconButton } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getUserAttachments } from '../../../utils/getUserImages';

const useStyles = makeStyles(theme => ({
  mt4: {
    marginTop: theme.spacing(4),
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
  uploadIcon: {
    marginBottom: '-20px',
    marginTop: '-24px',
  },
  removeIcon: {
    padding: 0,
    margin: 0,
  },
  hidden: {
    display: 'none',
  },
}));

const AddEmailDialog = ({ onClose, onSubmit, defaultEmailAddress }) => {
  const classes = useStyles();
  const [switchValue, setSwitchValue] = useState('template');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const attachments = getUserAttachments(files);

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  const handleSwitchChange = (event, form) => {
    setSwitchValue(event.target.value);
    form.change('template', null);
    form.change('message', null);
    form.change('subject', null);
  };

  const handleSubmit = values => {
    const emailsList = [];
    setLoading(true);

    const filesList = files.map(file => file.id);
    emailsList.push(values.destination);
    const { template_id, subject, message, communication_category, email_source } = values;
    onSubmit(emailsList, template_id, message, subject, communication_category, email_source, filesList);
  };

  const handleAttachFile = async e => {
    if (e.target.files[0].size > 10240000) {
      return notify('Maximum file size is 10 Mb', 'error');
    }
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0], 'file');

    await dataProvider
      .query('files/users', { method: 'POST', body: formData })
      .then(({ data }) => {
        setFiles(prevState => [...prevState, data]);
        setLoadingFile(false);
      })
      .catch(error => {
        setLoadingFile(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };

  const handleUnattachFile = id => setFiles(prevState => [...prevState.filter(i => i.id !== id)]);

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth size="lg">
      <DialogTitle id="form-dialog-title">Add user email</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ destination: defaultEmailAddress }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field name="email_source" validate={required('This field is required')}>
                    {({ input, meta }) => (
                      <Autocomplete
                        resource="email_outbox_addresses"
                        source="email_source"
                        optionValueProp="email"
                        optionLabelProp="email"
                        variant="standard"
                        margin="none"
                        size="medium"
                        {...input}
                        error={meta.touched && meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="destination" validate={required('This field is required')}>
                    {({ input, meta }) => (
                      <TextField
                        required
                        fullWidth
                        label="Destination"
                        {...input}
                        error={meta.touched && meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="communication_category">
                    {({ input }) => (
                      <Autocomplete
                        resource="communication_categories"
                        source="communication_category"
                        optionValueProp="id"
                        optionLabelProp="name"
                        variant="standard"
                        margin="none"
                        size="medium"
                        {...input}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor="upload_file">
                    <input className={classes.hidden} type="file" id="upload_file" onChange={handleAttachFile} />
                    <Button
                      color="primary"
                      aria-label="upload file"
                      className={classes.uploadIcon}
                      startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                      component="span">
                      Attach file
                    </Button>
                  </label>
                </Grid>
                {files.length > 0 && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography>Attached files:</Typography>
                      {attachments.map(file => (
                        <Box key={file.src} className={classes.flex}>
                          <Link
                            href={file.src}
                            underline="none"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.flex}>
                            <AttachmentIcon color="secondary" fontSize="small" />
                            {file.file_name}
                          </Link>
                          <IconButton
                            color="primary"
                            aria-label="unattach file"
                            className={classes.removeIcon}
                            component="span"
                            edge="start"
                            onClick={() => handleUnattachFile(file.id)}>
                            <CloseIcon color="secondary" fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="template message switch"
                      name="switch"
                      value={switchValue}
                      onChange={e => {
                        handleSwitchChange(e, form);
                      }}>
                      <FormControlLabel value="template" control={<Radio size="small" />} label="Use template" />
                      <FormControlLabel value="message" control={<Radio size="small" />} label="Use message" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field name="subject" validate={required('This field is required')}>
                    {({ input, meta }) => (
                      <TextField
                        fullWidth
                        label="Subject"
                        {...input}
                        error={meta.touched && meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                {switchValue === 'template' && (
                  <Grid item xs={12}>
                    <Field name="template_id" validate={required('This field is required')}>
                      {({ input, meta }) => (
                        <Autocomplete
                          resource="templates"
                          source="template_id"
                          label="Template"
                          optionValueProp="id"
                          optionLabelProp="key"
                          filter={{ category: 'Email', sending_type: 'manual', is_enabled: true }}
                          variant="standard"
                          margin="none"
                          size="medium"
                          {...input}
                          onFieldChange={(_e, newValue) => {
                            form.change('subject', newValue?.subject ?? null);
                          }}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {switchValue === 'message' && (
                  <Grid item xs={12}>
                    <Field name="message" validate={required('This field is required')}>
                      {({ input, meta }) => (
                        <TextField
                          margin="none"
                          multiline
                          minRows="3"
                          fullWidth
                          label="Message"
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
              <DialogActions className={classes.mt4}>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : null}
                  color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddEmailDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultEmailAddress: PropTypes.string,
};

AddEmailDialog.defaultProps = {
  defaultEmailAddress: '',
};

export default AddEmailDialog;
