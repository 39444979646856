import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateTimePickerInput from '../input/DateTimePickerInput';

const DateRangeFilter = ({
  id,
  title,
  filter = {},
  form,
  label_after = 'Created after',
  label_before = 'Created before',
}) => {
  const { meta } = useField(id);

  return (
    <>
      <Divider />
      <Box p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              defaultChecked={filter?.active}
              onChange={e => form.change(`${id}.active`, e.target.checked ? [id] : [])}
            />
          }
          label={title}
        />
        <DateTimePickerInput
          label={label_after}
          source={`${id}.values.from`}
          defaultValue={filter?.values?.[0]?.from}
        />
        <DateTimePickerInput
          label={label_before}
          source={`${id}.values.to`}
          before
          defaultValue={filter?.values?.[0]?.to}
        />
        {meta.touched && meta.error && (
          <FormHelperText style={{ textAlign: 'center' }} error={meta.touched && meta.error}>
            {meta.error}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

DateRangeFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  label_after: PropTypes.string,
  label_before: PropTypes.string,
};

DateRangeFilter.defaultProps = {
  filter: {},
};

export default DateRangeFilter;
