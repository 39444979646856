import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import FlagIcon from '@material-ui/icons/Flag';
import { dangerPrincipalValue, warningPrincipalValue } from '../../../../constants';
import { formatCurrency } from '../../../../utils/formatter';

const ComparePrincipalsBox = ({ principalValue }) => (
  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
    {principalValue >= warningPrincipalValue && principalValue < dangerPrincipalValue ? (
      <WarningIcon fontSize="small" htmlColor="#d46e00" />
    ) : principalValue >= dangerPrincipalValue ? (
      <FlagIcon fontSize="small" htmlColor="red" />
    ) : null}
    {principalValue < warningPrincipalValue ? formatCurrency(principalValue) : <b>{formatCurrency(principalValue)}</b>}
  </Box>
);

ComparePrincipalsBox.propTypes = {
  principalValue: PropTypes.number,
};

export default ComparePrincipalsBox;
