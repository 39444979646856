import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
  SelectArrayInput,
  BooleanInput,
  useNotify,
  useDataProvider,
  useRefresh,
  NullableBooleanInput,
} from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import { blue, orange, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { formatDatetime, formatPhoneNumber, formatCurrency } from '../../../utils/formatter';
import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import QuickFilterInput from '../../filter/QuickFilterInput';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import Call from '../../call/Call';
import { useCollectionGroups } from '../../../hooks/useCollectionGroups';
import { useHandbook } from '../../../hooks/useHandbook';
import { getUserId } from '../../../utils/authentication';
import { marginZeroStyles } from '../../../constants';
import ExclusivePairInput from '../../input/ExclusivePairInput';
import ExclusiveFourOptInput from '../../input/ExclusiveFourOptInput';
import CollectionBulkActionButtons from './CollectionBulkActionButtons';
import ComparePrincipalsBox from './components/ComparePrincipalsBox';
import { PTP_STATUS_CHOICES } from '../../../utils/dictionary';

const useStyles = makeStyles(() => ({
  minWidth240: {
    minWidth: 240,
  },
  ...marginZeroStyles,
}));

const lastCallResult = ({ last_call_contact_type, last_call_contact_result, last_call_promise_type }) => {
  if (!last_call_contact_type && !last_call_contact_result && !last_call_promise_type) {
    return '---';
  }

  return (
    <>
      {last_call_contact_type ?? '---'}
      <br />
      {last_call_contact_result ?? '---'}
      <br />
      {last_call_promise_type ?? '---'}
    </>
  );
};

const ListFilter = props => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const { data: typeValues } = useHandbook('collection_contact_type_values', { items_per_page: 1000 });
  const { data: collectionGroups } = useCollectionGroups();

  const contactTypes = typeValues
    .filter(item => item.type_name === 'Contact type')
    .map(item => {
      return { id: item.id, name: item.value };
    });
  const contactResults = typeValues
    .filter(item => item.type_name === 'Contact result')
    .map(item => {
      return { id: item.id, name: item.value };
    });
  const promiseTypes = typeValues
    .filter(item => item.type_name === 'Promise type')
    .map(item => {
      return { id: item.id, name: item.value };
    });

  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'defaulted', name: 'Defaulted' },
  ];

  const ptpStatusChoices = [{ id: 'without_ptp', name: 'Without PTP' }, ...PTP_STATUS_CHOICES];

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectArrayInput
        label="Collection group"
        source="collection_group__"
        choices={collectionGroups}
        className={classes.minWidth240}
        alwaysOn
      />
      <NumberInput label="Min principal" source="principal|gte" alwaysOn />
      <NumberInput label="Max principal" source="principal|lte" alwaysOn />
      <NumberInput label="Min days past due" source="days_past_due|gte" alwaysOn />
      <NumberInput label="Max days past due" source="days_past_due|lte" alwaysOn />
      <NullableBooleanInput label="Is favorite" source="is_favorite" alwaysOn />
      <ExclusiveFourOptInput
        label="PTP"
        firstOption={{ source: 'is_ptp_today', label: 'exp. today' }}
        secondOption={{ source: 'is_ptp_tomorrow', label: 'exp. tomorrow' }}
        thirdOption={{ source: 'is_ptp_broken_1', label: 'broken 1' }}
        fourthOption={{ source: 'is_ptp_broken_3', label: 'broken 3' }}
        alwaysOn
        resettable
      />
      <ExclusivePairInput
        label="Coverage"
        firstOption={{ source: 'not_covered', label: 'not covered' }}
        secondOption={{ source: 'not_covered_today', label: 'not covered today' }}
        alwaysOn
        resettable
      />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      {permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') && (
        <AsyncSelectInput
          label="Collector Id"
          source="collector->id"
          query={{
            resource:
              'admins?role_permission=CAN_BE_COLLECTION_SPECIALIST&is_blocked=false&items_per_page=200&order[username]=asc',
            payload: { pagination: {}, sort: {}, filter: {} },
          }}
          mapper={({ id, username }) => ({ value: id, name: username })}
        />
      )}
      <BooleanInput source="exists|collector" label="Has assigned collector" defaultValue={false} />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <NumberInput label="Phone number" source="user->phoneBooks->phoneNumber" />
      <SelectInput label="Status" source="state" choices={statusChoices} />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Closed before" source="closed_at|before" before />
      <DateFilterInput label="Closed after" source="closed_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <SelectInput
        label="Loan type"
        source="is_repeat"
        choices={[
          { id: 'false', name: 'New' },
          { id: 'true', name: 'Repeated' },
        ]}
      />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <DateFilterInput label="Last call before" source="lastPromiseToPay->createdAt|before" before />
      <DateFilterInput label="Last call after" source="lastPromiseToPay->createdAt|after" after />
      <SelectInput
        key={`last-call-contact-type-length-${contactTypes.length}`}
        label="Last call contact type"
        source="last_contact_call->contact_type->id"
        choices={contactTypes}
        className={classes.minWidth240}
      />
      <SelectInput
        key={`last-call-contact-results-length-${contactResults.length}`}
        label="Last call contact results"
        source="last_contact_call->contact_result->id"
        choices={contactResults}
        className={classes.minWidth240}
      />
      <SelectInput
        key={`last-call-promise-types-length-${promiseTypes.length}`}
        label="Last call promise Types"
        source="last_contact_call->promise_type->id"
        choices={promiseTypes}
        className={classes.minWidth240}
      />
      <SelectInput label="PTP status" source="ptp_status" choices={ptpStatusChoices} />
      <DateFilterInput label="Last PTP before" source="lastPromiseToPay->promiseDate|before" before />
      <DateFilterInput label="Last PTP after" source="lastPromiseToPay->promiseDate|after" after />
      <DateFilterInput label="Last extension before" source="last_extension_date|before" before />
      <DateFilterInput label="Last extension after" source="last_extension_date|after" after />
      <DateFilterInput label="Last payment before" source="last_payment_date|before" before />
      <DateFilterInput label="Last payment after" source="last_payment_date|after" after />
    </Filter>
  );
};

ListFilter.propTypes = {
  collectiongroups: PropTypes.array,
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export default props => {
  const [checkedLoans, setCheckedLoans] = useState([]);

  const userId = getUserId();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const { permissions = [] } = usePermissions();
  const canSeePhone = !permissions.includes('CAN_SEE_FULL_PHONE');

  const onRowClick = record => {
    setCheckedLoans([...checkedLoans, record.id]);
    localStorage.setItem('checked_collection_loans', JSON.stringify([...checkedLoans, record.id]));
    window.open(`#loans/${record.id}/show`, '_blank');
    return null;
  };

  const getRowStyle = record => {
    if (checkedLoans.includes(record.id)) {
      return { backgroundColor: blue[100] };
    }
  };

  const markAsFavorite = id => {
    dataProvider
      .query(`loans/${id}/mark_as_favorite`, { method: 'POST' })
      .then(() => {
        notify('Loan marked as favorite', 'info');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const unmarkAsFavorite = id => {
    dataProvider
      .query(`loans/${id}/unmark_as_favorite`, { method: 'POST' })
      .then(() => {
        notify('Loan unmarked as favorite', 'info');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  useEffect(() => {
    const checkedLoans = JSON.parse(localStorage.getItem('checked_collection_loans'));
    checkedLoans.length > 0 ? setCheckedLoans(checkedLoans) : setCheckedLoans([]);
  }, []);

  if (permissions.length === 0) return null;

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<CollectionBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      filter={{ enum_state: 'active,defaulted', external_agency_has: false }}
      filterDefaultValues={permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') ? null : { 'collector.id': userId }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowStyle={getRowStyle} rowClick={(_id, _basePath, record) => onRowClick(record)}>
        <TextField source="id" />
        <FunctionField
          render={record =>
            record.is_favorite ? (
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  unmarkAsFavorite(record.id);
                }}>
                <StarIcon fontSize="small" htmlColor={orange[400]} />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  markAsFavorite(record.id);
                }}>
                <StarOutlineIcon fontSize="small" htmlColor={grey[400]} />
              </IconButton>
            )
          }
        />
        <FunctionField
          render={record =>
            checkedLoans.includes(record.id) && (
              <IconButton size="small">
                <VisibilityIcon color="secondary" fontSize="small" />
              </IconButton>
            )
          }
        />
        <FunctionField
          sortBy="days_past_due"
          label="DPD"
          render={record => {
            return (
              <Chip
                avatar={
                  <Avatar>
                    {record.collection_group
                      ? record.collection_group.replace(' New', '').replace(' Repeat', '')
                      : 'Er'}
                  </Avatar>
                }
                label={record.days_past_due}
              />
            );
          }}
        />
        <FunctionField
          label="Client"
          render={({ user_id, user_first_name = '', user_last_name = '', user_phone_number }) => {
            return (
              <>
                <Link
                  onClick={e => e.stopPropagation()}
                  href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>
                <Call userId={user_id} userPhone={user_phone_number}>
                  {formatPhoneNumber(user_phone_number, canSeePhone)}
                </Call>
              </>
            );
          }}
        />
        <TextField source="assigned_collector" label="Collector" sortable={false} />
        <FunctionField
          sortBy="principal"
          label="Principal"
          render={({ principal }) => <ComparePrincipalsBox principalValue={principal} />}
        />
        <FunctionField label="Debt amount" render={({ accrued_total }) => formatCurrency(accrued_total)} />
        <NumberField source="tenor" />
        <ChipField size="small" source="state" sortable={false} />
        <FunctionField
          key="is_repeat"
          label="Loan type"
          source="is_repeat"
          sortable={false}
          render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
        />
        <FunctionField source="created_at" render={({ created_at }) => formatDatetime(created_at)} label="Created at" />
        <FunctionField source="matured_at" render={({ matured_at }) => formatDatetime(matured_at)} label="Matured at" />
        <FunctionField
          source="lastContactCall.createdAt"
          render={({ last_call_date }) => formatDatetime(last_call_date)}
          label="Last call date"
        />
        <FunctionField
          render={row =>
            lastCallResult({
              last_call_contact_type: row.last_call_contact_type,
              last_call_contact_result: row.last_call_contact_result,
              last_call_promise_type: row.last_call_promise_type,
            })
          }
          label="Last call result"
        />
        <FunctionField
          source="lastPromiseToPay.promiseDate"
          render={({ last_promise_to_pay_date }) => formatDatetime(last_promise_to_pay_date)}
          label="Last promise to pay date"
        />
        <FunctionField
          source={undefined}
          render={({ last_extension_date }) => formatDatetime(last_extension_date)}
          label="Last extension date"
        />
        <FunctionField
          source={undefined}
          render={({ last_payment_date }) => formatDatetime(last_payment_date)}
          label="Last payment date"
        />
      </Datagrid>
    </List>
  );
};
