import { MARKETING_EVENTS_SEND_FROM_OPTIONS, MARKETING_EVENTS_SEND_TO_OPTIONS } from '../../../utils/dictionary';

export const GLOBAL_SETTINGS_OPTIONS_MAP = {
  verification: {
    title: 'Verification',
    items: {
      webitel_validate_call_on_registration: {
        title: 'Webitel validate call on registration',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      selfie_photo_liveness_system: {
        title: 'Selfie photo liveness-system',
        options: [
          { id: 'hyperverge', name: 'Hyperverge' },
          { id: 'ozforensics', name: 'Ozforensics' },
        ],
      },
      auto_start_de: {
        title: 'Autostart DE',
        options: [
          { id: 1, name: 'Manual' },
          { id: 2, name: 'Aventus DE' },
        ],
      },
      auto_start_juicy_score_config: {
        title: 'Juicy score',
        options: [
          { id: 1, name: 'only on first clients' },
          { id: 2, name: 'for all clients' },
        ],
      },
      verifier_auto_assignment_config: {
        title: 'Verifier auto assignment',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      regula_required_on_registration: {
        title: 'Is regula response required on registration',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      geolocation_config: {
        title: 'Geolocation',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      need_stp_cep_before_disbursement: {
        title: 'Need stp cep before disbursement',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      need_stp_cep_before_approving: {
        title: 'Need stp cep before approving',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      overdue_from_stp_check_before_manual_verification: {
        title: 'Overdue in minute from stp check before manual verification',
        options: [
          { id: 5, name: '5' },
          { id: 10, name: '10' },
          { id: 20, name: '20' },
          { id: 30, name: '30' },
          { id: 40, name: '40' },
          { id: 50, name: '50' },
          { id: 60, name: '60' },
          { id: 70, name: '70' },
          { id: 80, name: '80' },
          { id: 90, name: '90' },
          { id: 100, name: '100' },
          { id: 110, name: '110' },
          { id: 120, name: '120' },
          { id: 140, name: '140' },
          { id: 160, name: '160' },
          { id: 180, name: '180' },
          { id: 200, name: '200' },
          { id: 250, name: '250' },
          { id: 300, name: '300' },
        ],
      },
      stp_cep_name_similar_check: {
        title: 'Percent similarity for name STP check',
        options: [
          { id: 50, name: '50%' },
          { id: 55, name: '55%' },
          { id: 60, name: '60%' },
          { id: 65, name: '65%' },
          { id: 70, name: '70%' },
          { id: 75, name: '75%' },
          { id: 80, name: '80%' },
          { id: 85, name: '85%' },
          { id: 90, name: '90%' },
          { id: 95, name: '95%' },
          { id: 100, name: '100%' },
        ],
      },
      stp_cep_check_overdue: {
        title: 'Overdue in minute from STP check to send money',
        options: [
          { id: 10, name: '10' },
          { id: 11, name: '11' },
          { id: 12, name: '12' },
          { id: 13, name: '13' },
          { id: 14, name: '14' },
          { id: 15, name: '15' },
          { id: 16, name: '16' },
          { id: 17, name: '17' },
          { id: 18, name: '18' },
          { id: 19, name: '19' },
          { id: 20, name: '20' },
        ],
      },
      regula_similar_check_replace_enabled: {
        title: 'Enable regula name replace',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      regula_similar_check_replace: {
        title: 'Regula name replace if similarity more than',
        options: [
          { id: 60, name: '60%' },
          { id: 65, name: '65%' },
          { id: 70, name: '70%' },
          { id: 75, name: '75%' },
          { id: 80, name: '80%' },
          { id: 85, name: '85%' },
          { id: 90, name: '90%' },
          { id: 95, name: '95%' },
        ],
      },
      use_ipinfo_for_get_country: {
        title: 'Use ipinfo for get country',
        options: [
          { id: 0, name: 'Disable' },
          { id: 1, name: 'Enable' },
        ],
      },
      show_loan_info_limit_value: {
        title: 'Show loan info limit value',
      },
    },
  },
  finance: {
    title: 'Finance',
    items: {
      auto_money_transfer_config: {
        title: 'Auto money transfer',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      default_auto_money_transfer: {
        title: 'Default auto money transfer',
        options: [
          { id: 1, name: 'Fake' },
          { id: 2, name: 'Openpay' },
          { id: 3, name: 'Conekta' },
          { id: 4, name: 'STP' },
          { id: 5, name: 'Unlimit' },
        ],
      },
      default_charge_provider: {
        title: 'Default charge provider',
        options: [
          { id: 1, name: 'Fake' },
          { id: 2, name: 'Openpay' },
          { id: 3, name: 'Conekta' },
          { id: 4, name: 'STP' },
          { id: 5, name: 'Unlimit' },
        ],
      },
      euro_currency: {
        title: 'Euro currency',
      },
      load_invoicing_reports: {
        title: 'Load invoicing reports',
        options: [
          { id: 0, name: 'Disable' },
          { id: 1, name: 'Enable' },
        ],
      },
      bank_for_direct_debit: {
        title: 'Bank for direct debit',
        options: [
          { id: 1, name: 'STP' },
          { id: 2, name: 'BBVA' },
          { id: 3, name: 'SANTANDER' },
          { id: 4, name: 'INBURSA' },
        ],
      },
    },
  },
  notification: {
    title: 'Notification',
    items: {
      allow_send_real_sms: {
        title: 'Allow send real sms',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      default_sms_transmitter: {
        title: 'Default SMS transmitter',
        options: [
          { id: 1, name: 'Fake' },
          { id: 2, name: 'Amazon SNS' },
          { id: 7, name: 'Airmovil' },
        ],
      },
      repeat_sms_transmitter: {
        title: 'SMS transmitter for repeat SMS',
        options: [
          { id: 1, name: 'Fake' },
          { id: 2, name: 'Amazon SNS' },
          { id: 7, name: 'Airmovil' },
        ],
      },
      change_sms_provider_after_error_count: {
        title: 'Change sms provider after error count',
        options: [
          { id: 0, name: 'Don\t change provider' },
          { id: 5, name: '5' },
          { id: 10, name: '10' },
          { id: 20, name: '20' },
          { id: 30, name: '30' },
          { id: 40, name: '40' },
          { id: 50, name: '50' },
          { id: 60, name: '60' },
          { id: 70, name: '70' },
          { id: 80, name: '80' },
          { id: 90, name: '90' },
          { id: 100, name: '100' },
          { id: 150, name: '150' },
          { id: 200, name: '200' },
        ],
      },
      list_of_emails_for_income_sms: {
        title: 'Emails for income sms',
      },
      send_first_sms_for_country: {
        title: 'Send first sms for country',
        options: [
          { id: 0, name: 'Mexico' },
          { id: 1, name: 'Any' },
        ],
      },
      send_repeat_sms_for_country: {
        title: 'Send repeat sms for country',
        options: [
          { id: 0, name: 'Mexico' },
          { id: 1, name: 'Any' },
        ],
      },
      count_phone_numbers_recovery: {
        title: 'Count phone numbers recovery',
      },
      log_notification: {
        title: 'Log notification',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      max_manual_sms_per_day: {
        title: 'Max manual sms per day',
        options: [
          { id: 1, name: '1' },
          { id: 2, name: '2' },
          { id: 3, name: '3' },
          { id: 4, name: '4' },
          { id: 5, name: '5' },
          { id: 6, name: '6' },
          { id: 7, name: '7' },
          { id: 8, name: '8' },
        ],
      },
      max_manual_email_per_day: {
        title: 'Max manual email per day',
        options: [
          { id: 1, name: '1' },
          { id: 2, name: '2' },
          { id: 3, name: '3' },
          { id: 4, name: '4' },
          { id: 5, name: '5' },
          { id: 6, name: '6' },
          { id: 7, name: '7' },
          { id: 8, name: '8' },
        ],
      },
    },
  },
  collection: {
    title: 'Collection',
    items: {
      promise_complete_amount_part: {
        title: 'Promise complete amount part',
        options: [
          { id: 50, name: '50%' },
          { id: 55, name: '55%' },
          { id: 60, name: '60%' },
          { id: 65, name: '65%' },
          { id: 70, name: '70%' },
          { id: 75, name: '75%' },
          { id: 80, name: '80%' },
          { id: 85, name: '85%' },
          { id: 90, name: '90%' },
          { id: 95, name: '95%' },
          { id: 100, name: '100%' },
        ],
      },
      related_phones_add_by: {
        title: 'Related phones added by',
        options: [
          { id: 0, name: 'Client' },
          { id: 1, name: 'Client + Admin' },
          { id: 2, name: 'Client + Credit history bureau' },
          { id: 3, name: 'Client + Admin + Credit history bureau' },
          { id: 4, name: 'All' },
        ],
      },
      related_phones_search_phone_by: {
        title: 'Related phones search phone by',
        options: [
          { id: 0, name: 'Client' },
          { id: 1, name: 'Client + Admin' },
          { id: 2, name: 'Client + Credit history bureau' },
          { id: 3, name: 'Client + Admin + Credit history bureau' },
        ],
      },
      auto_extended_all: {
        title: 'Auto extended all',
        options: [
          { id: 0, name: 'Disable' },
          { id: 1, name: 'Enable' },
        ],
      },
      auto_debit_percent_write_off: {
        title: 'AutoDebit percent write-off',
        options: [
          { id: 0, name: 'Disable write-off' },
          { id: 1, name: '1%' },
          { id: 2, name: '2%' },
          { id: 3, name: '3%' },
          { id: 4, name: '4%' },
          { id: 5, name: '5%' },
          { id: 6, name: '6%' },
          { id: 7, name: '7%' },
          { id: 8, name: '8%' },
          { id: 9, name: '9%' },
          { id: 10, name: '10%' },
          { id: 11, name: '11%' },
          { id: 12, name: '12%' },
          { id: 13, name: '13%' },
          { id: 14, name: '14%' },
          { id: 15, name: '15%' },
          { id: 16, name: '16%' },
          { id: 17, name: '17%' },
          { id: 18, name: '18%' },
          { id: 19, name: '19%' },
          { id: 20, name: '20%' },
          { id: 21, name: '21%' },
          { id: 22, name: '22%' },
          { id: 23, name: '23%' },
          { id: 24, name: '24%' },
          { id: 25, name: '25%' },
        ],
      },
      register_payment_method_after_dpd: {
        title: 'Register payment method after DPD',
        options: [
          { id: 1, name: '1' },
          { id: 2, name: '2' },
          { id: 3, name: '3' },
          { id: 4, name: '4' },
          { id: 5, name: '5' },
          { id: 6, name: '6' },
          { id: 7, name: '7' },
          { id: 8, name: '8' },
          { id: 9, name: '9' },
          { id: 10, name: '10' },
        ],
      },
      collection_auto_assign_consider_loans: {
        title: 'Consider loans already assigned to collectors',
        options: [
          { id: 0, name: 'Not use old loans' },
          { id: 1, name: 'Use principal from old loans' },
          { id: 2, name: 'Use total from old loans' },
        ],
      },
      collection_auto_assign_sort_loans: {
        title: 'Sort loans before auto assign',
        options: [
          { id: 0, name: 'Sort by dpd and sum' },
          { id: 1, name: 'Sort by sum' },
        ],
      },
      expire_extension: {
        title: 'Expire extension after days',
        options: [
          { id: 0, name: '-' },
          { id: 3, name: '3' },
          { id: 4, name: '4' },
          { id: 5, name: '5' },
          { id: 6, name: '6' },
          { id: 7, name: '7' },
          { id: 8, name: '8' },
          { id: 9, name: '9' },
          { id: 10, name: '10' },
        ],
      },
      main_phone_aws_validate_on_registration: {
        title: 'Main user phone AWS validation required on registration',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      contact_phone_aws_validate_on_registration: {
        title: 'Contact user phone AWS validation required on registration',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
    },
  },
  compliance: {
    title: 'Compliance',
    items: {
      uma_value: {
        title: 'UMA',
      },
      sat_blacklist_check: {
        title: 'Sat blacklist check',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      annual_disbursement_limit: {
        title: 'Annual disbursement limit',
      },
      client_tracking_disbursement_limit: {
        title: 'Client tracking disbursement limit',
      },
      credit_bureau_response_validity_period: {
        title: 'Credit bureau response validity period',
        options: [
          { id: 15, name: '15' },
          { id: 30, name: '30' },
          { id: 45, name: '45' },
          { id: 60, name: '60' },
          { id: 90, name: '90' },
          { id: 120, name: '120' },
        ],
      },
    },
  },
  marketing: {
    title: 'Marketing',
    items: {
      marketing_events_send_from: {
        title: 'Marketing events: send from',
        options: MARKETING_EVENTS_SEND_FROM_OPTIONS,
      },
      marketing_events_send_to: {
        title: 'Marketing events: send to',
        options: MARKETING_EVENTS_SEND_TO_OPTIONS,
      },
      referral_value: {
        title: 'Referral value',
      },
      ab_test_default_product_code: {
        title: 'Ab test default product code',
        options: [
          { id: 'pdl', name: 'pdl' },
          { id: 'pdl2', name: 'pdl2' },
        ],
      },
      ab_test_product_code: {
        title: 'Ab test product code',
        options: [
          { id: 'pdl', name: 'pdl' },
          { id: 'pdl2', name: 'pdl2' },
        ],
      },
      ab_test_product_percent: {
        title: 'Ab test product percent',
        options: [
          { id: 5, name: '5' },
          { id: 10, name: '10' },
          { id: 15, name: '15' },
          { id: 20, name: '20' },
          { id: 25, name: '25' },
          { id: 30, name: '30' },
          { id: 35, name: '35' },
          { id: 40, name: '40' },
          { id: 45, name: '45' },
          { id: 50, name: '50' },
          { id: 55, name: '55' },
          { id: 60, name: '60' },
          { id: 65, name: '65' },
          { id: 70, name: '70' },
          { id: 75, name: '75' },
          { id: 80, name: '80' },
          { id: 85, name: '85' },
          { id: 90, name: '90' },
          { id: 95, name: '95' },
          { id: 100, name: '100' },
        ],
      },
      email_unsubscribe_link: {
        title: 'Email unsubscribe link',
      },
      min_score_for_affiliate: {
        title: 'Min score for affiliate',
      },
      default_reject_goto_url: {
        title: 'Default reject goto url',
      },
    },
  },
  security: {
    title: 'Security',
    items: {
      create_request_limiter: {
        title: 'Enable limiter for create request',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      admin_password_lifetime: {
        title: 'Admin password lifetime',
        options: [
          { id: 0, name: 'Do not change' },
          { id: 1, name: '2 weeks' },
          { id: 2, name: '1 month' },
          { id: 3, name: '2 months' },
          { id: 4, name: '3 months' },
        ],
      },
    },
  },
};
