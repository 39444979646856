import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Field, Form as FinalForm } from 'react-final-form';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { required, useDataProvider } from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import noop from '../../utils/noop';

const useStyles = makeStyles(() => ({
  form: {
    width: 400,
  },
}));

const CollectionNotPaymentReasonFormDialog = ({ isOpened, onClose, onSubmit }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const handleSubmit = values => {
    const data = {
      enabled: values.enabled,
      name: values.name,
    };

    dataProvider
      .fetch('collection_no_payment_reasons', {
        body: JSON.stringify(data),
        method: 'POST',
      })
      .then(() => onSubmit());
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <FinalForm
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>Create new no payment reason</DialogTitle>
            <DialogContent>
              <Field name="name" validate={required('This field is required')}>
                {({ input, meta }) => (
                  <TextField
                    type="text"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    id="motivator-name-input"
                    label="Motivator name"
                    variant="filled"
                    margin="normal"
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
              <Field name="enabled" type="checkbox" initialValue={true}>
                {({ input }) => (
                  <FormControlLabel
                    control={<Switch checked={input.checked} onChange={input.onChange} color="primary" />}
                    label="Is enabled"
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CollectionNotPaymentReasonFormDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CollectionNotPaymentReasonFormDialog.defaultProps = {
  onClose: noop,
  onSubmit: noop,
};

export default CollectionNotPaymentReasonFormDialog;
