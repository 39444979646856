import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  SaveButton,
  NumberInput,
  FormWithRedirect,
  required,
  maxLength,
  minValue,
  BooleanInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAdminsList } from './getAdminsList';
import { integerValidator } from '../../../utils/validator';

const useStyles = makeStyles(() => ({
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    dataProvider
      .getList('admins', {
        filter: {},
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setLoading(false));
  }, [dataProvider, notify]);

  return (
    <FormWithRedirect
      save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      Update collection group
                    </Typography>
                    <BooleanInput
                      source="is_repeat"
                      label="Is repeat?"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="code" label="Group code" validate={[maxLength(60), required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="ptp_term"
                        label="Max PTP term"
                        min={0}
                        validate={[integerValidator(), minValue(0, 'The value must be a positive number'), required()]}
                        helperText={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="dpd_from"
                        label="Days past due from"
                        validate={[required()]}
                        helperText={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="dpd_to"
                        label="Days past due to"
                        validate={[required()]}
                        helperText={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2">Assigned to admins</Typography>
                      {loading ? <CircularProgress size={15} /> : getAdminsList(admins, record.code)}
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    code: PropTypes.string,
  }),
};

const CollectionGroupEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default CollectionGroupEdit;
