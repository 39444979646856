import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

import { formatDatetime, formatPhoneNumber } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxWidth: '300px',
  },
}));

const NotificationTable = ({
  userId = null,
  applicationId = null,
  loanId = null,
  refreshedAt,
  onTransition,
  inactive = false,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const [notifications, setNotifications] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('notifications', {
        filter: {
          ...(userId ? { 'user.id': userId } : {}),
          ...(applicationId ? { 'application.id': applicationId } : {}),
          ...(loanId ? { 'loan.id': loanId } : {}),
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setNotifications(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, applicationId, loanId, refreshedAt, page, perPage]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(notifications)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Delivery</TableCell>
            <TableCell>Transmitter</TableCell>
            <TableCell>Issuer</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Sent at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Chip size="small" label={row.state} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.delivery_status} />
              </TableCell>
              <TableCell>{row.transmitter_id}</TableCell>
              <TableCell>{row.issuer_id !== null ? 'manual' : 'system'}</TableCell>
              <TableCell className={classes.breakableCell}>
                {/^\d{10,11}$/.test(row.destination) ? formatPhoneNumber(row.destination) : row.destination}
              </TableCell>
              <TableCell>{row.template_key}</TableCell>
              <TableCell className={classes.breakableCell}>{row.message}</TableCell>
              <TableCell>{row.sent_at && formatDatetime(row.sent_at)}</TableCell>
              <TableCell align="right">
                <ButtonGroup
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={permissions.indexOf('CAN_NOTIFICATION_EDIT') === -1}>
                  {row.enabled_transitions.indexOf('resend') !== -1 && (
                    <Button disabled={inactive} onClick={() => onTransition(row.id, 'resend')}>
                      Resend
                    </Button>
                  )}
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

NotificationTable.propTypes = {
  userId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
  inactive: PropTypes.bool,
};

export default NotificationTable;
